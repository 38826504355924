import { defineComponent as _defineComponent } from 'vue'
import { normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, renderSlot as _renderSlot, vShow as _vShow, withDirectives as _withDirectives, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"
import _imports_0 from '../assets/collapsible/dropdown.png'


const _hoisted_1 = { class: "collapsible-main" }
const _hoisted_2 = { class: "title" }
const _hoisted_3 = { class: "collapsible-content" }
const _hoisted_4 = {
  key: 0,
  class: "disabled-overlay"
}

import { ref } from 'vue';
import { CollapsibleSectionProps } from './componentModel/CollapsibleModel';


export default /*@__PURE__*/_defineComponent({
  __name: 'CollapsibleSection',
  props: {
    title: {},
    isDisabled: { type: Boolean, default: false }
  },
  setup(__props: any) {



// #region Data
const isOpen = ref(true);
// #endregion Data

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", {
      class: _normalizeClass(['title-container', { 'disabled': _ctx.isDisabled }]),
      onClick: _cache[0] || (_cache[0] = ($event: any) => (isOpen.value = !isOpen.value))
    }, [
      _createElementVNode("img", {
        class: _normalizeClass({ 'isOpen': isOpen.value && !_ctx.isDisabled }),
        src: _imports_0,
        alt: ""
      }, null, 2),
      _createElementVNode("div", _hoisted_2, _toDisplayString(_ctx.title), 1)
    ], 2),
    _withDirectives(_createElementVNode("div", _hoisted_3, [
      _renderSlot(_ctx.$slots, "default")
    ], 512), [
      [_vShow, isOpen.value && !_ctx.isDisabled]
    ]),
    (_ctx.isDisabled)
      ? (_openBlock(), _createElementBlock("div", _hoisted_4))
      : _createCommentVNode("", true)
  ]))
}
}

})