<template>
  <div :class="styles.container">
    <nav :class="styles.header">
      <div :class="styles.headerContainer">{{ pageTitle }}</div>
    </nav>

    <aside :class="styles.sidebar">
      <div :class="styles.logoContainer" @click="handleClickLogo">
        <img :class="styles.logo" src="/img/logo.5c6f3dda.png" />
        <div :class="styles.title">Tri-Factor</div>
      </div>
      <div :class="styles.sidebarBox">
        <div
          v-for="(sidebar, sIdx) in props.sidebarData"
          :key="sIdx"
          :class="styles.sidebarItemContainer"
        >
          <div v-if="sidebar.section" :class="styles.itemSection">
            {{ sidebar.section }}
          </div>
          <div
            v-for="(item, iIdx) in sidebar.items"
            :key="iIdx"
            :class="[
              styles.itemLabelContainer,
              item.route == route.fullPath && styles.active__,
            ]"
            @click="handleClickSidebarItem(item)"
          >
            <img :class="styles.itemIcon" src="../assets/layout/home.png" />
            <div :class="styles.sidebarLabel">{{ item.label }}</div>
          </div>
        </div>
      </div>
    </aside>
    <main :class="styles.main">
      <div :class="styles.mainContainer">
        <slot></slot>
      </div>
    </main>
  </div>
</template>

<script setup lang="ts">
import { defineProps, defineEmits, ref } from "vue";
import {
  LayoutProps,
  SidebarItemDataProps,
} from "./componentModel/LayoutModel";
import { useRoute, useRouter } from "vue-router";

//#region Data
const props = defineProps<LayoutProps>();
const emits = defineEmits<{
  (event: "onClickMenu", item: SidebarItemDataProps): void; // Define the event type
}>();

const router = useRouter();
const route = useRoute();
const pageTitle = ref<string>("Kitchen");
//#endregion

//#region Handler
const handleClickSidebarItem = (item: SidebarItemDataProps) => {
  router.push(item.route);
  pageTitle.value = item.label;
  emits("onClickMenu", item);
};

const handleClickLogo = () => {
  router.push("/kitchen");
  pageTitle.value = "Kitchen";
};
//#endregion
</script>

<style module="styles" lang="scss">
$header-height: 84px;
$sidebar-width: 250px;

.container {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  width: 100%;
  background: #ededef;
  overflow: hidden;
  color: var(--black);
}

//#region Header
.header {
  position: sticky;
  top: 0;
  display: flex;
  align-items: center;
  height: $header-height;
  z-index: var(--z-header);
  padding-left: $sidebar-width;
  font-size: var(--font-x-large);
  font-weight: 600;
}
.headerContainer {
  display: flex;
  align-items: center;
  width: 100%;
  height: 100%;
  padding-left: 32px;
}
//#endregion

//#region Sidebar
.sidebar {
  display: flex;
  flex-direction: column;
  padding: 16px 20px;
  position: fixed;
  top: 0;
  left: 0;
  min-height: 100%;
  width: $sidebar-width;
  background: #fff;
  z-index: var(--z-sidebar);
  gap: 16px;
}
.logoContainer {
  display: flex;
  align-items: center;
  gap: 8px;
  font-weight: 500;
  font-size: var(--font-medium);
  pointer-events: all;
  cursor: pointer;
}
.logo {
  width: 100%;
  max-width: 52px;
  pointer-events: none;
}
.title {
  pointer-events: none;
}
.sidebarItemContainer {
  margin-top: 16px;

  &:first-child {
    margin-top: 0;
  }
}
.itemSection {
  font-size: var(--font-small);
  margin-bottom: 12px;
}
.itemLabelContainer {
  display: flex;
  align-items: center;
  gap: 16px;
  padding: 12px 16px;
  border-radius: 10px;
  cursor: pointer;
  transition: all 300ms;

  &.active__ {
    background: var(--primary);
    box-shadow: var(--shadow);
  }
}
.sidebarLabel {
}
.itemIcon {
  height: 16px;
}
//#endregion

//#region Main
.main {
  display: flex;
  flex-direction: column;
  padding-left: $sidebar-width;
}
.mainContainer {
  padding: 0 32px;
}
//#endregion
</style>
