import { defineComponent as _defineComponent } from 'vue'
import { normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderSlot as _renderSlot, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withModifiers as _withModifiers, renderList as _renderList, Fragment as _Fragment } from "vue"
import _imports_0 from '../assets/fileupload/upload.png'
import _imports_1 from '../assets/fileupload/cross-red.png'


const _hoisted_1 = { key: 0 }
const _hoisted_2 = ["onClick"]
const _hoisted_3 = ["title"]
const _hoisted_4 = ["onClick"]
const _hoisted_5 = ["multiple", "accept"]

import {
  FileUploadEmits,
  FileUploadProps,
} from "./componentModel/FileUploadModel";
import { ref, watch } from "vue";

//#region Data

export default /*@__PURE__*/_defineComponent({
  __name: 'FileUpload',
  props: {
    fileList: {},
    accept: { default: "" },
    multiple: { type: Boolean, default: true },
    label: {},
    placeholder: { default: "Drag file here or browse" }
  },
  emits: ["update:fileList", "clickFile", "fileChange"],
  setup(__props: any, { emit: __emit }) {

const fileInput = ref<HTMLInputElement | null>(null);
const fileList = ref<File[]>([]);
const isDragging = ref(false);
//#endregion

const props = __props;
const emit = __emit;

//#region Handler
const handleClickFileInput = () => {
  fileInput.value?.click();
};

const handleDragOver = () => {
  isDragging.value = true;
};

const handleDragLeave = () => {
  isDragging.value = false;
};

const handleFileDrop = (ev: DragEvent) => {
  isDragging.value = false;
  if (ev.dataTransfer && ev.dataTransfer.files) {
    const droppedFiles = Array.from(ev.dataTransfer.files);
    droppedFiles.forEach((file) => {
      fileList.value.push(file);
    });

    updateFileList(droppedFiles);
  }
};

const handleFileUploadChange = (ev: Event) => {
  let target = ev.target as HTMLInputElement;
  if (target.files) {
    const selectedFiles = Array.from(target.files);
    selectedFiles.forEach((file) => {
      fileList.value.push(file);
    });

    updateFileList(selectedFiles);
  }
};

const handleRemoveFile = (index: number) => {
  fileList.value.splice(index, 1);
  emit("update:fileList", fileList.value);
};

const updateFileList = (file: File[]) => {
  fileList.value = [...file];
  emit("update:fileList", fileList.value);
  emit("fileChange", fileList.value);
};
//#endregion

watch(() => props.fileList, (val) => {
  if (val) {
    fileList.value = val;
  }
})

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(_ctx.styles.fileUploadContainer)
  }, [
    _createElementVNode("div", {
      class: _normalizeClass([_ctx.styles.fileUploadInput, isDragging.value && _ctx.styles.isDrag__]),
      onClick: handleClickFileInput,
      onDragover: _withModifiers(handleDragOver, ["prevent"]),
      onDragleave: handleDragLeave,
      onDrop: _withModifiers(handleFileDrop, ["prevent"])
    }, [
      _createElementVNode("img", {
        class: _normalizeClass(_ctx.styles.fileUploadIcon),
        src: _imports_0,
        alt: "Upload File"
      }, null, 2),
      (isDragging.value)
        ? (_openBlock(), _createElementBlock("div", _hoisted_1, "Drop it here"))
        : _createCommentVNode("", true),
      (!isDragging.value)
        ? _renderSlot(_ctx.$slots, "default", { key: 1 }, () => [
            _createTextVNode(_toDisplayString(props.placeholder), 1)
          ])
        : _createCommentVNode("", true)
    ], 34),
    _createElementVNode("div", {
      class: _normalizeClass([_ctx.styles.fileList, fileList.value.length && _ctx.styles.marginTop__])
    }, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(fileList.value, (file, idx) => {
        return (_openBlock(), _createElementBlock("div", {
          class: _normalizeClass(_ctx.styles.fileListItem),
          key: idx,
          onClick: ($event: any) => (emit('clickFile', file))
        }, [
          _createElementVNode("span", {
            class: _normalizeClass(_ctx.styles.fileListName),
            title: file?.name
          }, _toDisplayString(file?.name), 11, _hoisted_3),
          _createElementVNode("img", {
            class: _normalizeClass(_ctx.styles.fileListRemove),
            src: _imports_1,
            onClick: _withModifiers(($event: any) => (handleRemoveFile(idx)), ["stop"]),
            alt: "Delete File"
          }, null, 10, _hoisted_4)
        ], 10, _hoisted_2))
      }), 128))
    ], 2),
    _createElementVNode("input", {
      ref_key: "fileInput",
      ref: fileInput,
      type: "file",
      hidden: "",
      onChange: handleFileUploadChange,
      multiple: _ctx.multiple,
      accept: _ctx.accept
    }, null, 40, _hoisted_5)
  ], 2))
}
}

})