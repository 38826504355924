import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, unref as _unref, renderSlot as _renderSlot } from "vue"
import _imports_0 from '../assets/layout/home.png'


const _hoisted_1 = ["onClick"]

import { ref } from "vue";
import {
  LayoutProps,
  SidebarItemDataProps,
} from "./componentModel/LayoutModel";
import { useRoute, useRouter } from "vue-router";

//#region Data

export default /*@__PURE__*/_defineComponent({
  __name: 'Layout',
  props: {
    headerTitle: {},
    sidebarData: {}
  },
  emits: ["onClickMenu"],
  setup(__props: any, { emit: __emit }) {

const props = __props;
const emits = __emit;

const router = useRouter();
const route = useRoute();
const pageTitle = ref<string>("Kitchen");
//#endregion

//#region Handler
const handleClickSidebarItem = (item: SidebarItemDataProps) => {
  router.push(item.route);
  pageTitle.value = item.label;
  emits("onClickMenu", item);
};

const handleClickLogo = () => {
  router.push("/kitchen");
  pageTitle.value = "Kitchen";
};
//#endregion

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(_ctx.styles.container)
  }, [
    _createElementVNode("nav", {
      class: _normalizeClass(_ctx.styles.header)
    }, [
      _createElementVNode("div", {
        class: _normalizeClass(_ctx.styles.headerContainer)
      }, _toDisplayString(pageTitle.value), 3)
    ], 2),
    _createElementVNode("aside", {
      class: _normalizeClass(_ctx.styles.sidebar)
    }, [
      _createElementVNode("div", {
        class: _normalizeClass(_ctx.styles.logoContainer),
        onClick: handleClickLogo
      }, [
        _createElementVNode("img", {
          class: _normalizeClass(_ctx.styles.logo),
          src: "/img/logo.5c6f3dda.png"
        }, null, 2),
        _createElementVNode("div", {
          class: _normalizeClass(_ctx.styles.title)
        }, "Tri-Factor", 2)
      ], 2),
      _createElementVNode("div", {
        class: _normalizeClass(_ctx.styles.sidebarBox)
      }, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(props.sidebarData, (sidebar, sIdx) => {
          return (_openBlock(), _createElementBlock("div", {
            key: sIdx,
            class: _normalizeClass(_ctx.styles.sidebarItemContainer)
          }, [
            (sidebar.section)
              ? (_openBlock(), _createElementBlock("div", {
                  key: 0,
                  class: _normalizeClass(_ctx.styles.itemSection)
                }, _toDisplayString(sidebar.section), 3))
              : _createCommentVNode("", true),
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(sidebar.items, (item, iIdx) => {
              return (_openBlock(), _createElementBlock("div", {
                key: iIdx,
                class: _normalizeClass([
              _ctx.styles.itemLabelContainer,
              item.route == _unref(route).fullPath && _ctx.styles.active__,
            ]),
                onClick: ($event: any) => (handleClickSidebarItem(item))
              }, [
                _createElementVNode("img", {
                  class: _normalizeClass(_ctx.styles.itemIcon),
                  src: _imports_0
                }, null, 2),
                _createElementVNode("div", {
                  class: _normalizeClass(_ctx.styles.sidebarLabel)
                }, _toDisplayString(item.label), 3)
              ], 10, _hoisted_1))
            }), 128))
          ], 2))
        }), 128))
      ], 2)
    ], 2),
    _createElementVNode("main", {
      class: _normalizeClass(_ctx.styles.main)
    }, [
      _createElementVNode("div", {
        class: _normalizeClass(_ctx.styles.mainContainer)
      }, [
        _renderSlot(_ctx.$slots, "default")
      ], 2)
    ], 2)
  ], 2))
}
}

})