/* eslint-disable @typescript-eslint/no-explicit-any */
import { HubConnectionBuilder } from "@microsoft/signalr";
import store from "../store/index";
import { MutationPayload } from "vuex";

const dayShort = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];
const dayLong = [
  "Sunday",
  "Monday",
  "Tuesday",
  "Wednesday",
  "Thursday",
  "Friday",
  "Saturday",
];
const monthShort = [
  "Jan",
  "Feb",
  "Mar",
  "Apr",
  "May",
  "Jun",
  "Jul",
  "Aug",
  "Sep",
  "Oct",
  "Nov",
  "Dec",
];
const monthLong = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];

let questionSub: any = null; // Set the default question subscribe

// Hub details enum
export const hubDetails = {
  BUILDINGHUBNAME: "buildingSelectionHub",
  BUILDINGEXTENDTIME: "ShareTimeExtended",
};

export const dateFormat = (date: Date, format: string) => {
  let result = format;

  // Replacing the date or day
  result = result.replace(/dddd/g, dayLong[date.getDay()]);
  result = result.replace(/ddd/g, dayShort[date.getDay()]);
  result = result.replace(/dd/g, padNumber(date.getDate()));
  result = result.replace(/d/g, date.getDate().toString());

  // Replacing the month
  result = result.replace(/MMMM/g, monthLong[date.getMonth()]);
  result = result.replace(/MMM/g, monthShort[date.getMonth()]);
  result = result.replace(/MM/g, padNumber(date.getMonth() + 1));
  result = result.replace(/M/g, (date.getMonth() + 1).toString());

  // Replacing the year
  result = result.replace(/yyyy/g, date.getFullYear().toString());
  result = result.replace(/yy/g, date.getFullYear().toString().substring(2));

  // Formatting the hour minute and second
  result = result.replace(/HH/g, padNumber(date.getHours()));
  result = result.replace(/mm/g, padNumber(date.getMinutes()));
  result = result.replace(/ss/g, padNumber(date.getSeconds()));

  return result;
};
// Padding a number with provided char and final length of the number
export const padNumber = (num: number, char = "0", pad = 2) => {
  // Convert the number to string
  let result = num.toString();
  // Padding the result with the char provided
  while (result.length < pad) {
    result = `${char}${result}`;
  }

  return result;
};
// Round a number to a certain decimal places
export const roundNumber = (num: number, decimal: number) => {
  num = Number(num);
  decimal = Number(decimal);

  if (!isNaN(num) || !isNaN(decimal)) {
    return Math.round(num * Math.pow(10, decimal)) / Math.pow(10, decimal);
  } else {
    console.error(
      `num: (${num}) OR decimal: (${decimal}) is not a number or undefined`
    );
    return 0;
  }
};
// To fake waiting
export const wait = (time: number) => {
  // Time in milliseconds
  return new Promise((r: any) => setTimeout(() => r(), time));
};
// To assign action when posting
export const compareData = (oriObj: any, newObj: any, compareKey: string) => {
  // Getting the details of the object with the updated or inserted action
  const objToPost = newObj.value
    .map((newitem: any) => {
      // Checking if the current exists in ori
      const exists = oriObj.value.find(
        (ori: any) => newitem[compareKey] == ori[compareKey]
      );
      if (exists) {
        // If exists, need to compare all the values and update
        if (JSON.stringify(newitem) != JSON.stringify(exists)) {
          // Comparing the new item and ori if it is different
          // Setting the action to U for update
          const obj = JSON.parse(JSON.stringify(newitem));
          obj.action = "U";
          return obj;
        } else {
          return null;
        }
      } else {
        // Insert a new value
        // Setting the action to I for insert
        const obj = JSON.parse(JSON.stringify(newitem));
        obj.action = "I";
        return obj;
      }
    })
    .filter((p: any) => p);

  // Getting the details of the spaces that has been deleted
  const deletedObj = oriObj.value
    .map((ori: any) => {
      // Finding the one in ori that is in the current
      const deleted = newObj.value.find(
        (space: any) => space[compareKey] == ori[compareKey]
      );
      if (!deleted) {
        // If does not exists set the action to D
        const obj = JSON.parse(JSON.stringify(ori));
        obj.action = "D";
        return obj;
      } else {
        // If exists, do nothing
        return null;
      }
    })
    .filter((d: any) => d);

  // Adding the deleted obj into the return obj
  objToPost.push(...deletedObj);

  return objToPost;
};
// To call the generic popup from the App
export const popup = (header?: string, content?: string) => {
  // Commit the show popup with header and content (Return a default value for the header and content if not passed)
  store.commit("showAlert", {
    header: header ?? "Header not set",
    content: content ?? "Content not set",
  });
};
/**
 * To show a popup with question
 *
 * @param header The header that will be shown in popup
 * @param content The content of the popup can be in HTML format
 * @param yesText The text that will be shown for the yes (top) button
 *
 * ***Default: "Yes"***
 * @param noText The text that will be shown for the no (bottom) button
 *
 * ***Default: "No"***
 * @param isDestructive If set to true, the red background of button will be at the yes (top) button
 *
 * ***Default: false***
 * @returns The text of the yes or no button
 */
export const question = (
  header: string,
  content: string,
  yesText = "Yes",
  noText = "No",
  isDestructive = false
) => {
  // Set the resolve obj to from the promise so that this method is awaitable
  let resolve: any = null;
  const prom = new Promise((r) => (resolve = r));

  // Commit the show popup with header and content (Return a default value for the header and content if not passed)
  store.commit("showAlertQuestion", {
    header: header ?? "Header not set",
    content: content ?? "Content not set",
    yesText: yesText ?? "Yes",
    noText: noText ?? "No",
    isDestructive: isDestructive,
  });

  // Unsubscribe if it is already subscribe
  if (questionSub) {
    questionSub();
  }

  // Subscribe when the button in the popup is clicked (either yes/no)
  questionSub = store.subscribe((mutation: MutationPayload) => {
    if (mutation.type == "questionAnswered") {
      resolve(mutation.payload.answer);
    }
  });

  return prom;
};

/**
 * To show notification
 * @param text The text to show in the bottom notification (snackbar/toast styled notification).
 * @param theme To set the background color of the noti.
 * - "default": no color
 * - "warning": goldenrod
 * - "error": indianred
 * - "success": limegreen
 *
 * ***Default: "default"***
 * @param expires To set how long the noti will be shown in ms. Pass 0 to show the noti forever.
 *
 * ***Default: 3000***
 */
export const showNoti = (
  text: string,
  theme: "default" | "warning" | "error" | "success" = "default",
  expires = 3000
) => {
  // Commit the show notification mutation from store
  store.commit("showNoti", {
    text: text,
    expires: expires,
    theme: theme, // Can be default, warning, error, success
  });
};
// To find a difference between 2 dates
export const dateDiff = (date1: Date, date2: Date) => {
  const totalMillis = date1.getTime() - date2.getTime();

  // The milliseconds
  const millis = totalMillis % 1000;

  // Getting the hours
  const fullHours = totalMillis / 1000 / 60 / 60;
  const hours = Math.floor(fullHours);

  // Getting the minutes
  const fullMinute = (fullHours - hours) * 60;
  const minute = Math.floor(fullMinute);

  // Getting the seconds
  const fullSecond = (fullMinute - minute) * 60;
  const second = Math.floor(fullSecond);

  return {
    hours: hours,
    minute: minute,
    second: second,
    millis: millis,
    fullText: () => {
      const text = [];
      if (hours > 0) {
        text.push(`${hours} ${hours > 1 ? "Hours" : "Hour"}`);
      }
      if (minute > 0) {
        text.push(`${minute} ${minute > 1 ? "Minutes" : "Minute"}`);
      }
      if (second > 0) {
        text.push(`${second} ${second > 1 ? "Seconds" : "Second"}`);
      }

      return text.join(" ");
    },
  };
};
// To build the connection hub to the SignalR
export const buildSignalR = (hubName: string) => {
  // Build the hub
  const hub = new HubConnectionBuilder()
    .withUrl(`https://samuel-test.reeqzan.com/${hubName}`)
    .build();
  return hub;
};
export const logout = () => {
  // To clear all the user details upon logout from the local storage
  localStorage.removeItem("user");
  localStorage.removeItem("loginTime");
  localStorage.removeItem("client");
  localStorage.removeItem("token");
  localStorage.removeItem("refresh");
  localStorage.removeItem("userid");
  localStorage.removeItem("role");

  // Clearing from the store
  store.state.currentClient = null;
};
// To be used to convert number to a currency formatted
export const formatNumber = (num: number, separator = ",", decimal = 2) => {
  // Checking if num is really a number
  if (isNaN(Number(num))) {
    console.error(`formatNumber: ${num} - is not a number`);
    return 0;
  }

  // Rounde the number first
  num = roundNumber(num, decimal);
  // Split the num to get the whole nunber and the decimal number
  const numSplit = num.toString().split(".");
  const wholeNum = numSplit[0];
  const decimalNum = numSplit[1] ?? "0";

  // Splitting the whole number
  const wholeNumSplit = wholeNum.split("").reverse();

  // Set the result to empty array
  const result = [];
  for (let x = 0; x < wholeNumSplit.length; x += 3) {
    // Slice and reverse
    result.push(
      wholeNumSplit
        .slice(x, x + 3)
        .reverse()
        .join("")
    );
  }

  // Reverse the array since the last inserted is the first value
  result.reverse();

  // Return the result
  return `${result.join(separator)}.${decimalNum}${
    decimalNum.length < 2 ? "0" : ""
  }`;
};
// To be used to get the number value if the value is already formatted
export const getNumber = (formatted: string) => {
  // Checking the formatted if there is a value
  if (formatted) {
    // The the digits and dot only
    const allNumber = formatted.match(/\d|\./g);

    if (allNumber) {
      // Checking if digit and dot is found
      if (allNumber.length > 0) {
        // Return the number value
        return Number(allNumber.join(""));
      } else {
        // Returning 0 since no digit is found
        return 0;
      }
    } else {
      return 0;
    }
  } else {
    // Returning 0 if the formatted is null/empty/undefined
    return 0;
  }
};
// To convert a file object to img src
export const fileToImgSrc = (file: any) => {
  // Setting an empty resolve to make this method awaitable
  let res: any = null;
  const prom = new Promise((r) => (res = r));

  // Creating a file reader
  const reader = new FileReader();
  // Reading the file
  reader.readAsDataURL(file);

  // Handling when the reader loaded
  reader.onload = (e: any) => {
    // Return the img src
    res(e.target.result);
  };

  // Return the promise
  return prom;
};
