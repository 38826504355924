<template>
  <Card :title="`Example ${fileList.length}`">
    <FileUpload
      v-model:fileList="fileList"
      @clickFile="handleClickFile"
      @fileChange="handleFileChange"
    />
  </Card>
</template>

<script setup lang="ts">
import { ref } from "vue";
import { Card, FileUpload } from "../../imports/components.import";
const fileList = ref<File[]>([]);

const handleClickFile = (file: File) => {
  console.log("handleClickFile", file);
};

const handleFileChange = (file: File[]) => {
  console.log("handleFileChange", file);
};
</script>
