<template>
  <div class="collapsible-main">
    <div :class="['title-container', { 'disabled': isDisabled }]" @click="isOpen = !isOpen">
      <img :class="{ 'isOpen': isOpen && !isDisabled }" src="../assets/collapsible/dropdown.png" alt="">
      <div class="title">{{ title }}</div>
    </div>
    <div v-show="isOpen && !isDisabled" class="collapsible-content">
      <slot></slot>
    </div>
    <div class="disabled-overlay" v-if="isDisabled"></div>
  </div>
</template>

<script setup lang="ts">
import { ref, defineProps, withDefaults } from 'vue';
import { CollapsibleSectionProps } from './componentModel/CollapsibleModel';

withDefaults(defineProps<CollapsibleSectionProps>(), {
  isDisabled: false
})

// #region Data
const isOpen = ref(true);
// #endregion Data
</script>

<style scoped>
.collapsible-main {
  border: 1px solid gray;
  padding: 10px;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  gap: 10px;
  position: relative;
}
.title-container {
  display: flex;
  align-items: center;
  gap: 10px;
  cursor: pointer;
  user-select: none;

  > img {
    height: 20px;
    transition: 0.3s;

    &.isOpen {
      transform: rotate(180deg);
    }
  }

  &.disabled {
    opacity: 0.5;
  }
}
.disabled-overlay {
  background-color: rgba(128,128,128,0.3);
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  z-index: 9;
}
.collapsible-content {
  display: flex;
  flex-direction: column;
  gap: 5px;
}
</style>