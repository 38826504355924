<template>
  <button
    :class="[
      styles.buttonContainer,
      styles[theme],
      clearBackground && styles.transparent,
      !activeNoScale && styles.buttonContainerScale,
    ]"
    :disabled="props.disabled || props.loading"
    @click="emit('click')"
  >
    <slot> Button </slot>
    <div v-if="props.loading" :class="styles.loadingContainer">
      <div :class="styles.loadingDot"></div>
      <div :class="styles.loadingDot"></div>
      <div :class="styles.loadingDot"></div>
    </div>
    <div
      v-if="props.disabled"
      :class="[styles.loadingContainer, styles.disabledContainer]"
    ></div>
  </button>
</template>

<script setup lang="ts">
/* eslint-disable */
import { defineProps, defineEmits } from "vue";
import { ButtonProps } from "./componentModel/ButtonModel";

const props = withDefaults(defineProps<ButtonProps>(), {
  theme: "default",
  disabled: false,
  loading: false,
  activeNoScale: false,
  clearBackground: false,
});

const emit = defineEmits(["click"]);
</script>

<style module="styles" lang="scss">
.buttonContainer {
  position: relative;
  width: 100%;
  padding: 10px;
  border-radius: 10px;
  border: none;
  cursor: pointer;
  background-color: var(--primary);
  transition: 0.3s;
  font-size: 16px;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 3px 0px,
    rgba(0, 0, 0, 0.06) 0px 1px 2px 0px;

  &:focus {
    outline-color: var(--primary);
  }

  &:disabled {
    pointer-events: none;
    outline: none;
    opacity: 1;
    color: inherit;
  }

  &.transparent {
    background-color: transparent;
    outline: solid 2px var(--primary);
  }

  &.submit {
    background-color: var(--blue);
    color: var(--white);

    &:focus {
      outline-color: var(--blue);
    }

    &.transparent {
      background-color: transparent;
      outline: solid 2px var(--blue);
      color: var(--black);
    }
  }

  &.danger {
    background-color: var(--red);
    color: white;

    &:focus {
      outline-color: var(--red);
    }

    &.transparent {
      background-color: transparent;
      outline: solid 2px var(--red);
      color: var(--black);
    }
  }

  &.warning {
    background-color: var(--yellow);

    &:focus {
      outline-color: var(--yellow);
    }

    &.transparent {
      background-color: transparent;
      outline: solid 2px var(--yellow);
    }
  }
}
.buttonContainerScale:active {
  transform: scale(0.97);
  box-shadow: rgba(0, 0, 0, 0.06) 0px 2px 4px 0px inset;
}

.loadingContainer {
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  background-color: rgba(180, 180, 180, 0.5);
  backdrop-filter: blur(2px);
  border-radius: 10px;
  z-index: 9;
  display: flex;
  align-items: center;
  justify-content: center;
  user-select: none;
}
.disabledContainer {
  backdrop-filter: none;
}
.loadingDot {
  border-radius: 100%;
  height: 0.8em;
  width: 0.8em;
  background-color: rgb(70, 70, 70);
  margin: 0 2px;
  transition: 0.3s;
  animation: loading-animation 1s linear infinite;
}
.loadingDot:nth-child(2) {
  animation-delay: 0.3s;
}
.loadingDot:nth-child(3) {
  animation-delay: 0.6s;
}
@keyframes loading-animation {
  0%,
  66%,
  100% {
    transform: scale(1);
  }
  33% {
    transform: scale(0.3);
  }
}
</style>
