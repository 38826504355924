import { defineComponent as _defineComponent } from 'vue'
import { renderSlot as _renderSlot, createTextVNode as _createTextVNode, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = ["disabled"]


import { ButtonProps } from "./componentModel/ButtonModel";


export default /*@__PURE__*/_defineComponent({
  __name: 'NewButton',
  props: {
    theme: { default: "default" },
    disabled: { type: Boolean, default: false },
    loading: { type: Boolean, default: false },
    activeNoScale: { type: Boolean, default: false },
    clearBackground: { type: Boolean, default: false }
  },
  emits: ["click"],
  setup(__props: any, { emit: __emit }) {

/* eslint-disable */
const props = __props;

const emit = __emit;

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("button", {
    class: _normalizeClass([
      _ctx.styles.buttonContainer,
      _ctx.styles[_ctx.theme],
      _ctx.clearBackground && _ctx.styles.transparent,
      !_ctx.activeNoScale && _ctx.styles.buttonContainerScale,
    ]),
    disabled: props.disabled || props.loading,
    onClick: _cache[0] || (_cache[0] = ($event: any) => (emit('click')))
  }, [
    _renderSlot(_ctx.$slots, "default", {}, () => [
      _cache[1] || (_cache[1] = _createTextVNode(" Button "))
    ]),
    (props.loading)
      ? (_openBlock(), _createElementBlock("div", {
          key: 0,
          class: _normalizeClass(_ctx.styles.loadingContainer)
        }, [
          _createElementVNode("div", {
            class: _normalizeClass(_ctx.styles.loadingDot)
          }, null, 2),
          _createElementVNode("div", {
            class: _normalizeClass(_ctx.styles.loadingDot)
          }, null, 2),
          _createElementVNode("div", {
            class: _normalizeClass(_ctx.styles.loadingDot)
          }, null, 2)
        ], 2))
      : _createCommentVNode("", true),
    (props.disabled)
      ? (_openBlock(), _createElementBlock("div", {
          key: 1,
          class: _normalizeClass([_ctx.styles.loadingContainer, _ctx.styles.disabledContainer])
        }, null, 2))
      : _createCommentVNode("", true)
  ], 10, _hoisted_1))
}
}

})