import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderSlot as _renderSlot, createElementVNode as _createElementVNode } from "vue"


import { CardProps } from "./componentModel/CardModel";

//#region Data
//#endregion

export default /*@__PURE__*/_defineComponent({
  __name: 'Card',
  props: {
    class: {},
    title: {}
  },
  setup(__props: any) {

const props = __props;

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(_ctx.styles.card)
  }, [
    (props.title)
      ? (_openBlock(), _createElementBlock("div", {
          key: 0,
          class: _normalizeClass(_ctx.styles.cardTitle)
        }, _toDisplayString(props.title), 3))
      : _createCommentVNode("", true),
    _createElementVNode("div", {
      class: _normalizeClass(props.class)
    }, [
      _renderSlot(_ctx.$slots, "default")
    ], 2)
  ], 2))
}
}

})