import { defineComponent as _defineComponent } from 'vue'
import { normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, unref as _unref, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

import Card from "@/components/Card.vue";
import { NewButton } from "@/imports/components.import";

export default /*@__PURE__*/_defineComponent({
  __name: 'ButtonPage',
  setup(__props) {


return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(_ctx.styles.container)
  }, [
    _createVNode(Card, {
      class: _normalizeClass(_ctx.styles.card),
      title: "Default"
    }, {
      default: _withCtx(() => [
        _createElementVNode("div", {
          class: _normalizeClass(_ctx.styles.btnContainer)
        }, [
          _createElementVNode("div", {
            class: _normalizeClass(_ctx.styles.btnTitle)
          }, "Default", 2),
          _createVNode(_unref(NewButton), null, {
            default: _withCtx(() => _cache[0] || (_cache[0] = [
              _createTextVNode("Default")
            ])),
            _: 1
          })
        ], 2),
        _createElementVNode("div", {
          class: _normalizeClass(_ctx.styles.btnContainer)
        }, [
          _createElementVNode("div", {
            class: _normalizeClass(_ctx.styles.btnTitle)
          }, "Disabled", 2),
          _createVNode(_unref(NewButton), { disabled: true }, {
            default: _withCtx(() => _cache[1] || (_cache[1] = [
              _createTextVNode("Default")
            ])),
            _: 1
          })
        ], 2),
        _createElementVNode("div", {
          class: _normalizeClass(_ctx.styles.btnContainer)
        }, [
          _createElementVNode("div", {
            class: _normalizeClass(_ctx.styles.btnTitle)
          }, "Loading", 2),
          _createVNode(_unref(NewButton), { loading: true }, {
            default: _withCtx(() => _cache[2] || (_cache[2] = [
              _createTextVNode("Default")
            ])),
            _: 1
          })
        ], 2),
        _createElementVNode("div", {
          class: _normalizeClass(_ctx.styles.btnContainer)
        }, [
          _createElementVNode("div", {
            class: _normalizeClass(_ctx.styles.btnTitle)
          }, "Transparent", 2),
          _createVNode(_unref(NewButton), { "clear-background": true }, {
            default: _withCtx(() => _cache[3] || (_cache[3] = [
              _createTextVNode("Default")
            ])),
            _: 1
          })
        ], 2)
      ]),
      _: 1
    }, 8, ["class"]),
    _createVNode(Card, {
      class: _normalizeClass(_ctx.styles.card),
      title: "Submit"
    }, {
      default: _withCtx(() => [
        _createElementVNode("div", {
          class: _normalizeClass(_ctx.styles.btnContainer)
        }, [
          _createElementVNode("div", {
            class: _normalizeClass(_ctx.styles.btnTitle)
          }, "Submit", 2),
          _createVNode(_unref(NewButton), { theme: "submit" }, {
            default: _withCtx(() => _cache[4] || (_cache[4] = [
              _createTextVNode("Submit")
            ])),
            _: 1
          })
        ], 2),
        _createElementVNode("div", {
          class: _normalizeClass(_ctx.styles.btnContainer)
        }, [
          _createElementVNode("div", {
            class: _normalizeClass(_ctx.styles.btnTitle)
          }, "Disabled", 2),
          _createVNode(_unref(NewButton), {
            theme: "submit",
            disabled: true
          }, {
            default: _withCtx(() => _cache[5] || (_cache[5] = [
              _createTextVNode("Submit")
            ])),
            _: 1
          })
        ], 2),
        _createElementVNode("div", {
          class: _normalizeClass(_ctx.styles.btnContainer)
        }, [
          _createElementVNode("div", {
            class: _normalizeClass(_ctx.styles.btnTitle)
          }, "Loading", 2),
          _createVNode(_unref(NewButton), {
            theme: "submit",
            loading: true
          }, {
            default: _withCtx(() => _cache[6] || (_cache[6] = [
              _createTextVNode("Submit")
            ])),
            _: 1
          })
        ], 2),
        _createElementVNode("div", {
          class: _normalizeClass(_ctx.styles.btnContainer)
        }, [
          _createElementVNode("div", {
            class: _normalizeClass(_ctx.styles.btnTitle)
          }, "Transparent", 2),
          _createVNode(_unref(NewButton), {
            theme: "submit",
            "clear-background": true
          }, {
            default: _withCtx(() => _cache[7] || (_cache[7] = [
              _createTextVNode("Submit")
            ])),
            _: 1
          })
        ], 2)
      ]),
      _: 1
    }, 8, ["class"]),
    _createVNode(Card, {
      class: _normalizeClass(_ctx.styles.card),
      title: "Danger"
    }, {
      default: _withCtx(() => [
        _createElementVNode("div", {
          class: _normalizeClass(_ctx.styles.btnContainer)
        }, [
          _createElementVNode("div", {
            class: _normalizeClass(_ctx.styles.btnTitle)
          }, "Danger", 2),
          _createVNode(_unref(NewButton), { theme: "danger" }, {
            default: _withCtx(() => _cache[8] || (_cache[8] = [
              _createTextVNode("Danger")
            ])),
            _: 1
          })
        ], 2),
        _createElementVNode("div", {
          class: _normalizeClass(_ctx.styles.btnContainer)
        }, [
          _createElementVNode("div", {
            class: _normalizeClass(_ctx.styles.btnTitle)
          }, "Disabled", 2),
          _createVNode(_unref(NewButton), {
            theme: "danger",
            disabled: true
          }, {
            default: _withCtx(() => _cache[9] || (_cache[9] = [
              _createTextVNode("Danger")
            ])),
            _: 1
          })
        ], 2),
        _createElementVNode("div", {
          class: _normalizeClass(_ctx.styles.btnContainer)
        }, [
          _createElementVNode("div", {
            class: _normalizeClass(_ctx.styles.btnTitle)
          }, "Loading", 2),
          _createVNode(_unref(NewButton), {
            theme: "danger",
            loading: true
          }, {
            default: _withCtx(() => _cache[10] || (_cache[10] = [
              _createTextVNode("Danger")
            ])),
            _: 1
          })
        ], 2),
        _createElementVNode("div", {
          class: _normalizeClass(_ctx.styles.btnContainer)
        }, [
          _createElementVNode("div", {
            class: _normalizeClass(_ctx.styles.btnTitle)
          }, "Transparent", 2),
          _createVNode(_unref(NewButton), {
            theme: "danger",
            "clear-background": true
          }, {
            default: _withCtx(() => _cache[11] || (_cache[11] = [
              _createTextVNode("Danger")
            ])),
            _: 1
          })
        ], 2)
      ]),
      _: 1
    }, 8, ["class"]),
    _createVNode(Card, {
      class: _normalizeClass(_ctx.styles.card),
      title: "Warning"
    }, {
      default: _withCtx(() => [
        _createElementVNode("div", {
          class: _normalizeClass(_ctx.styles.btnContainer)
        }, [
          _createElementVNode("div", {
            class: _normalizeClass(_ctx.styles.btnTitle)
          }, "Warning", 2),
          _createVNode(_unref(NewButton), { theme: "warning" }, {
            default: _withCtx(() => _cache[12] || (_cache[12] = [
              _createTextVNode("Warning")
            ])),
            _: 1
          })
        ], 2),
        _createElementVNode("div", {
          class: _normalizeClass(_ctx.styles.btnContainer)
        }, [
          _createElementVNode("div", {
            class: _normalizeClass(_ctx.styles.btnTitle)
          }, "Disabled", 2),
          _createVNode(_unref(NewButton), {
            theme: "warning",
            disabled: true
          }, {
            default: _withCtx(() => _cache[13] || (_cache[13] = [
              _createTextVNode("Warning")
            ])),
            _: 1
          })
        ], 2),
        _createElementVNode("div", {
          class: _normalizeClass(_ctx.styles.btnContainer)
        }, [
          _createElementVNode("div", {
            class: _normalizeClass(_ctx.styles.btnTitle)
          }, "Loading", 2),
          _createVNode(_unref(NewButton), {
            theme: "warning",
            loading: true
          }, {
            default: _withCtx(() => _cache[14] || (_cache[14] = [
              _createTextVNode("Warning")
            ])),
            _: 1
          })
        ], 2),
        _createElementVNode("div", {
          class: _normalizeClass(_ctx.styles.btnContainer)
        }, [
          _createElementVNode("div", {
            class: _normalizeClass(_ctx.styles.btnTitle)
          }, "Transparent", 2),
          _createVNode(_unref(NewButton), {
            theme: "warning",
            "clear-background": true
          }, {
            default: _withCtx(() => _cache[15] || (_cache[15] = [
              _createTextVNode("Warning")
            ])),
            _: 1
          })
        ], 2)
      ]),
      _: 1
    }, 8, ["class"])
  ], 2))
}
}

})