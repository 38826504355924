import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

import { ref } from "vue";
import { Card, FileUpload } from "../../imports/components.import";

export default /*@__PURE__*/_defineComponent({
  __name: 'FileUploadPage',
  setup(__props) {

const fileList = ref<File[]>([]);

const handleClickFile = (file: File) => {
  console.log("handleClickFile", file);
};

const handleFileChange = (file: File[]) => {
  console.log("handleFileChange", file);
};

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(_unref(Card), {
    title: `Example ${fileList.value.length}`
  }, {
    default: _withCtx(() => [
      _createVNode(_unref(FileUpload), {
        fileList: fileList.value,
        "onUpdate:fileList": _cache[0] || (_cache[0] = ($event: any) => ((fileList).value = $event)),
        onClickFile: handleClickFile,
        onFileChange: handleFileChange
      }, null, 8, ["fileList"])
    ]),
    _: 1
  }, 8, ["title"]))
}
}

})