import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, normalizeStyle as _normalizeStyle } from "vue"
import _imports_0 from '../assets/dropdown/dropdown.png'


const _hoisted_1 = {
  key: 0,
  class: "dropdown-label"
}
const _hoisted_2 = {
  key: 0,
  class: "error-text"
}
const _hoisted_3 = ["tabindex"]
const _hoisted_4 = {
  key: 0,
  class: "dropdown-search-selected"
}
const _hoisted_5 = ["disabled"]
const _hoisted_6 = { key: 2 }
const _hoisted_7 = {
  key: 3,
  class: "dropdown-placeholder"
}
const _hoisted_8 = ["onClick"]
const _hoisted_9 = {
  key: 2,
  class: "disabled-container"
}

import { ref, nextTick, watch, onMounted, VNodeRef } from 'vue'
import { DropdownProps, ItemBase } from './componentModel/DropdownModel';


export default /*@__PURE__*/_defineComponent({
  __name: 'Dropdown',
  props: {
    items: {},
    selected: {},
    placeholder: { default: "Label" },
    position: { default: 'bottom' },
    disabled: { type: Boolean, default: false },
    searchable: { type: Boolean, default: false },
    isRequired: { type: Boolean, default: false },
    hidePlaceholder: { type: Boolean, default: false }
  },
  emits: [
  'update:selected', // To handle 2 way binding for the selected when selectedObject changes
  'onChange'
],
  setup(__props: any, { emit: __emit }) {

const props = __props
const emit = __emit;

//#region Data
const showDropdown = ref(false); // To show or hide the dropdown
const dropdownContainerEl = ref<VNodeRef | null>(null); // The container for the dropdown
const dropdownItemEl = ref<VNodeRef[] | null>(null); // The element for the dropdown item
const dropdownSelectEl = ref<VNodeRef | null>(null); // The container element for the dropdown element
const dropdownItemHover = ref(false); // When hovering on the item to make sure that the item is clickable
const dropdownPositionSet = ref('unset'); // To set the dropdown position either top or bottom based on the screen height
const selectedObject = ref<ItemBase | null>(null); // Private selected object, bind to selected props

const searchText = ref(''); // The text that the user is searching
const searchTimeout = ref<any>(null); // The timeout of the searching
const dropdownItemsFiltered = ref<ItemBase[]>([]); // The filtered items of the dropdown
const currentObjectIndex = ref(-1); // The current dropdown index based on the keydown or arrow
const searchInputEl = ref<VNodeRef | null>(null);
//#endregion Data

//#region Methods
const itemClicked = (item: ItemBase) => {
  selectedObject.value = item;
  showDropdown.value = false;

  dropdownContainerEl.value.focus();

  dropdownItemHover.value = false;

  if (props.searchable) {
    searchInputEl.value.value = selectedObject.value.value;
  }
}
const dropdownKeydown = async (e: KeyboardEvent) => {
  // Not to do anything if the dropdown is not shown
  // if (!showDropdown.value) {
  //   return;
  // }

  if (e.key == ' ' && !props.searchable) {
    e.preventDefault();
    showDropdown.value = !showDropdown.value;
    
    await nextTick();
    if (showDropdown.value) {
      highlightDropdownItem();
    }
  } else if (e.key == 'Enter') {
    if (currentObjectIndex.value == -1) {
      if (dropdownItemsFiltered.value.length == 1) {
        currentObjectIndex.value = 0;
      } else {
        return;
      }
    }

    if (props.searchable) {
      // Set the selected object
      selectedObject.value = dropdownItemsFiltered.value[currentObjectIndex.value];
      // Hide the dropdown
      showDropdown.value = false;

      // Reset the dropdown list
      dropdownItemsFiltered.value = props.items;
      // Set the value of the input
      searchInputEl.value.value = selectedObject.value.value;
    } else {
      showDropdown.value = false;
    }
  } else if (e.key == 'ArrowUp' || e.key == 'ArrowDown') {
    e.preventDefault();
    
    if (e.key == 'ArrowUp') {
      currentObjectIndex.value -= 1;
    } else if (e.key == 'ArrowDown') {
      currentObjectIndex.value += 1;
    }

    if (currentObjectIndex.value < 0) {
      currentObjectIndex.value = dropdownItemsFiltered.value.length - 1;
    } else if (currentObjectIndex.value >= dropdownItemsFiltered.value.length) {
      currentObjectIndex.value = 0;
    }

    if (!props.searchable) {
      selectedObject.value = dropdownItemsFiltered.value[currentObjectIndex.value];
    }

    if (showDropdown.value) {
      highlightDropdownItem(currentObjectIndex.value);
    }
  } else if (e.key.length == 1 && !props.searchable) {
    // Setting the search text
    searchText.value += e.key;
    
    // Clear the timeout
    if (searchTimeout.value) {
      clearTimeout(searchTimeout.value);
    }

    // Set the timeout
    searchTimeout.value = setTimeout(() => {
      // Find the search text in the list
      let searchResult = props.items.find(i => {
        let regex = new RegExp(`^${searchText.value}`, 'gi');
        let match = i.value.match(regex);

        return match;
      });

      // Found result in the search result
      if (searchResult) {
        selectedObject.value = searchResult;
        // Move the selected item in the dropdown if shown
        if (showDropdown.value) {
          highlightDropdownItem();
        }
      }
      
      // Clear the search
      searchText.value = '';
      clearTimeout(searchTimeout.value);
    }, 250);
  }
}
const dropdownClicked = async () => {
  if (!props.searchable) {
    showDropdown.value = !showDropdown.value;
    await nextTick();
    highlightDropdownItem();
  }
}
const dropdownFocusOut = () => {
  if (!dropdownItemHover.value) {
    showDropdown.value = false;
  }
}
const highlightDropdownItem = (ind?: number) => {
  if (selectedObject.value) {
    if (ind === undefined) {
      ind = props.items.findIndex(i => i.value == selectedObject.value?.value);
    }
  } else if (props.searchable) {
    //
  } else {
    return;
  }

  dropdownItemEl.value?.forEach((i: any) => (i as HTMLElement).classList.remove('dropdown-selected'));
  
  let currentElement = (dropdownItemEl.value?.at(ind ?? 0)) as HTMLElement | undefined;
  currentElement?.classList.add('dropdown-selected');
  currentElement?.scrollIntoView({
    block: 'nearest'
  });
}
// When the search input is being typed
const searchInput = (e: Event) => {
  // Get the value and filter based on all items
  const searchText = (e.target as any).value;
  dropdownItemsFiltered.value = props.items.filter(i => i.value.toLowerCase().includes(searchText.toLowerCase()));

  // Clear the selectedobject if already selected
  if (selectedObject.value) {
    selectedObject.value = null;
    currentObjectIndex.value = -1;
  }

  // To open the dropdown if it is not opened
  if (!showDropdown.value) {
    showDropdown.value = true;
  }
}
//#endregion Methods

//#region Lifecycle
onMounted(() => {
  if (props.selected) {
    selectedObject.value = props.selected;
  }

  if (props.items) {
    dropdownItemsFiltered.value = props.items;
  }
});
//#endregion Lifecycle

//#region Watchers
watch(showDropdown, async (newVal) => {
  if (newVal) {
    await nextTick();
    if (!props.position) {
      let pageHeight = window.innerHeight;
      let dropdownSelectBottom = dropdownSelectEl.value.getBoundingClientRect().bottom;

      if (pageHeight <= dropdownSelectBottom) {
        dropdownSelectEl.value.classList.add('dropdown-select-container-top');
        dropdownPositionSet.value = 'top';
      }
    } else if (props.position == 'top') {
      dropdownSelectEl.value.classList.add('dropdown-select-container-top');
    }
    
    dropdownSelectEl.value.focus();

    // To reset the dropdown index
    currentObjectIndex.value = -1;
    // To set the current index to the selected object if an object is already selected
    if (selectedObject.value) {
      currentObjectIndex.value = dropdownItemsFiltered.value.findIndex(i => i.value == selectedObject.value?.value);
      highlightDropdownItem(currentObjectIndex.value);
    }
  }
})
watch(selectedObject, (val) => {
  // Handle when the selected object changes and update the selected prop for 2 way binding
  emit('update:selected', val);
  emit('onChange', val);
});
watch(() => props.selected, (val) => {
  selectedObject.value = val;
})
//#endregion Watchers

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", {
    class: "dropdown-main",
    style: _normalizeStyle({ opacity: _ctx.disabled ? '0.6' : '1' })
  }, [
    (!_ctx.hidePlaceholder)
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
          _createTextVNode(_toDisplayString(_ctx.placeholder) + " ", 1),
          (_ctx.isRequired)
            ? (_openBlock(), _createElementBlock("span", _hoisted_2, "**"))
            : _createCommentVNode("", true),
          _createTextVNode(" " + _toDisplayString(dropdownItemsFiltered.value.length != _ctx.items.length ? `(Showing ${dropdownItemsFiltered.value.length} of ${_ctx.items.length} items)` : ''), 1)
        ]))
      : _createCommentVNode("", true),
    _createElementVNode("div", {
      class: "dropdown-container",
      tabindex: _ctx.disabled || _ctx.searchable ? '-1' : '0',
      onKeydown: dropdownKeydown,
      onClick: dropdownClicked,
      onBlur: dropdownFocusOut,
      ref_key: "dropdownContainerEl",
      ref: dropdownContainerEl
    }, [
      (_ctx.searchable && selectedObject.value)
        ? (_openBlock(), _createElementBlock("div", _hoisted_4, _toDisplayString(selectedObject.value.value), 1))
        : _createCommentVNode("", true),
      (_ctx.searchable)
        ? (_openBlock(), _createElementBlock("input", {
            key: 1,
            class: "dropdown-search-input",
            type: "text",
            placeholder: "Search to select",
            onFocus: _cache[0] || (_cache[0] = ($event: any) => (showDropdown.value = true)),
            onBlur: dropdownFocusOut,
            onInput: searchInput,
            ref_key: "searchInputEl",
            ref: searchInputEl,
            onClick: _cache[1] || (_cache[1] = ($event: any) => (!showDropdown.value ? showDropdown.value = true : '')),
            disabled: _ctx.disabled
          }, null, 40, _hoisted_5))
        : (selectedObject.value)
          ? (_openBlock(), _createElementBlock("div", _hoisted_6, _toDisplayString(selectedObject.value.value), 1))
          : (_openBlock(), _createElementBlock("div", _hoisted_7, "Please Select")),
      _cache[4] || (_cache[4] = _createElementVNode("img", {
        class: "dropdown-icon",
        src: _imports_0,
        alt: ""
      }, null, -1))
    ], 40, _hoisted_3),
    (showDropdown.value)
      ? (_openBlock(), _createElementBlock("div", {
          key: 1,
          class: "dropdown-select-container",
          ref_key: "dropdownSelectEl",
          ref: dropdownSelectEl
        }, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(dropdownItemsFiltered.value, (item, ind) => {
            return (_openBlock(), _createElementBlock("div", {
              key: ind,
              class: "dropdown-item",
              onClick: ($event: any) => (itemClicked(item)),
              onMouseenter: _cache[2] || (_cache[2] = ($event: any) => (dropdownItemHover.value = true)),
              onMouseleave: _cache[3] || (_cache[3] = ($event: any) => (dropdownItemHover.value = false)),
              ref_for: true,
              ref_key: "dropdownItemEl",
              ref: dropdownItemEl
            }, _toDisplayString(item.value), 41, _hoisted_8))
          }), 128))
        ], 512))
      : _createCommentVNode("", true),
    (_ctx.disabled)
      ? (_openBlock(), _createElementBlock("div", _hoisted_9))
      : _createCommentVNode("", true)
  ], 4))
}
}

})