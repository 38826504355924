import { defineComponent as _defineComponent } from 'vue'
import { renderSlot as _renderSlot, createTextVNode as _createTextVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = ["disabled"]
const _hoisted_2 = {
  key: 0,
  class: "loading-container"
}
const _hoisted_3 = {
  key: 1,
  class: "loading-container disabled-container"
}


import { ButtonProps } from './componentModel/ButtonModel';


export default /*@__PURE__*/_defineComponent({
  __name: 'Button',
  props: {
    theme: { default: 'default' },
    disabled: { type: Boolean, default: false },
    loading: { type: Boolean, default: false },
    activeNoScale: { type: Boolean, default: false },
    clearBackground: { type: Boolean, default: false }
  },
  emits: [
  'click'
],
  setup(__props: any, { emit: __emit }) {

/* eslint-disable */
const props = __props;

const emit = __emit;

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("button", {
    class: _normalizeClass(['button-container', `${_ctx.theme}-theme`, _ctx.clearBackground && `transparent-${_ctx.theme}-theme`,
    { 'button-container-scale': !_ctx.activeNoScale }]),
    disabled: _ctx.disabled || _ctx.loading,
    onClick: _cache[0] || (_cache[0] = ($event: any) => (emit('click')))
  }, [
    _renderSlot(_ctx.$slots, "default", {}, () => [
      _cache[1] || (_cache[1] = _createTextVNode(" Button "))
    ]),
    (_ctx.loading)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, _cache[2] || (_cache[2] = [
          _createElementVNode("div", { class: "loading-dot" }, null, -1),
          _createElementVNode("div", { class: "loading-dot" }, null, -1),
          _createElementVNode("div", { class: "loading-dot" }, null, -1)
        ])))
      : _createCommentVNode("", true),
    (_ctx.disabled)
      ? (_openBlock(), _createElementBlock("div", _hoisted_3))
      : _createCommentVNode("", true)
  ], 10, _hoisted_1))
}
}

})