/* eslint-disable @typescript-eslint/no-empty-function */
import { WorkSpaceStandards } from "@/model/WorkspaceStandardsModel";
import { createStore } from "vuex";

export interface StoreState {
  currentClient: any;
  spaceListing: any;
  testingOfficeCustom: any;
  unitListing: any;
  countryListing: any;
  currencyListing: any;
  colorListing: any;
  mockWorkspaceStandard: WorkSpaceStandards[];
}

export default createStore<StoreState>({
  state: {
    currentClient: null, // The current location that user selected from the home page
    spaceListing: [], // The listing of the space that gotten from DB
    testingOfficeCustom: [], // This is just for testing [PENDING] to remove
    unitListing: [
      // The fix unit listing for now
      { value: "Square Meter (sqm)", acronym: "sqm" },
      { value: "Square Feet (sqft)", acronym: "sqft" },
    ],
    countryListing: [
      // The listing of all the countries
      { name: "Afghanistan", code: "AF", value: "Afghanistan (AF)" },
      { name: "Åland Islands", code: "AX", value: "Åland Islands (AX)" },
      { name: "Albania", code: "AL", value: "Albania (AL)" },
      { name: "Algeria", code: "DZ", value: "Algeria (DZ)" },
      { name: "American Samoa", code: "AS", value: "American Samoa (AS)" },
      { name: "AndorrA", code: "AD", value: "AndorrA (AD)" },
      { name: "Angola", code: "AO", value: "Angola (AO)" },
      { name: "Anguilla", code: "AI", value: "Anguilla (AI)" },
      { name: "Antarctica", code: "AQ", value: "Antarctica (AQ)" },
      {
        name: "Antigua and Barbuda",
        code: "AG",
        value: "Antigua and Barbuda (AG)",
      },
      { name: "Argentina", code: "AR", value: "Argentina (AR)" },
      { name: "Armenia", code: "AM", value: "Armenia (AM)" },
      { name: "Aruba", code: "AW", value: "Aruba (AW)" },
      { name: "Australia", code: "AU", value: "Australia (AU)" },
      { name: "Austria", code: "AT", value: "Austria (AT)" },
      { name: "Azerbaijan", code: "AZ", value: "Azerbaijan (AZ)" },
      { name: "Bahamas", code: "BS", value: "Bahamas (BS)" },
      { name: "Bahrain", code: "BH", value: "Bahrain (BH)" },
      { name: "Bangladesh", code: "BD", value: "Bangladesh (BD)" },
      { name: "Barbados", code: "BB", value: "Barbados (BB)" },
      { name: "Belarus", code: "BY", value: "Belarus (BY)" },
      { name: "Belgium", code: "BE", value: "Belgium (BE)" },
      { name: "Belize", code: "BZ", value: "Belize (BZ)" },
      { name: "Benin", code: "BJ", value: "Benin (BJ)" },
      { name: "Bermuda", code: "BM", value: "Bermuda (BM)" },
      { name: "Bhutan", code: "BT", value: "Bhutan (BT)" },
      { name: "Bolivia", code: "BO", value: "Bolivia (BO)" },
      {
        name: "Bosnia and Herzegovina",
        code: "BA",
        value: "Bosnia and Herzegovina (BA)",
      },
      { name: "Botswana", code: "BW", value: "Botswana (BW)" },
      { name: "Bouvet Island", code: "BV", value: "Bouvet Island (BV)" },
      { name: "Brazil", code: "BR", value: "Brazil (BR)" },
      {
        name: "British Indian Ocean Territory",
        code: "IO",
        value: "British Indian Ocean Territory (IO)",
      },
      {
        name: "Brunei Darussalam",
        code: "BN",
        value: "Brunei Darussalam (BN)",
      },
      { name: "Bulgaria", code: "BG", value: "Bulgaria (BG)" },
      { name: "Burkina Faso", code: "BF", value: "Burkina Faso (BF)" },
      { name: "Burundi", code: "BI", value: "Burundi (BI)" },
      { name: "Cambodia", code: "KH", value: "Cambodia (KH)" },
      { name: "Cameroon", code: "CM", value: "Cameroon (CM)" },
      { name: "Canada", code: "CA", value: "Canada (CA)" },
      { name: "Cape Verde", code: "CV", value: "Cape Verde (CV)" },
      { name: "Cayman Islands", code: "KY", value: "Cayman Islands (KY)" },
      {
        name: "Central African Republic",
        code: "CF",
        value: "Central African Republic (CF)",
      },
      { name: "Chad", code: "TD", value: "Chad (TD)" },
      { name: "Chile", code: "CL", value: "Chile (CL)" },
      { name: "China", code: "CN", value: "China (CN)" },
      { name: "Christmas Island", code: "CX", value: "Christmas Island (CX)" },
      {
        name: "Cocos (Keeling) Islands",
        code: "CC",
        value: "Cocos (Keeling) Islands (CC)",
      },
      { name: "Colombia", code: "CO", value: "Colombia (CO)" },
      { name: "Comoros", code: "KM", value: "Comoros (KM)" },
      { name: "Congo", code: "CG", value: "Congo (CG)" },
      {
        name: "Congo, The Democratic Republic of the",
        code: "CD",
        value: "Congo, The Democratic Republic of the (CD)",
      },
      { name: "Cook Islands", code: "CK", value: "Cook Islands (CK)" },
      { name: "Costa Rica", code: "CR", value: "Costa Rica (CR)" },
      { name: `Cote D'Ivoire`, code: "CI", value: `Cote D'Ivoire (CI)` },
      { name: "Croatia", code: "HR", value: "Croatia (HR)" },
      { name: "Cuba", code: "CU", value: "Cuba (CU)" },
      { name: "Cyprus", code: "CY", value: "Cyprus (CY)" },
      { name: "Czech Republic", code: "CZ", value: "Czech Republic (CZ)" },
      { name: "Denmark", code: "DK", value: "Denmark (DK)" },
      { name: "Djibouti", code: "DJ", value: "Djibouti (DJ)" },
      { name: "Dominica", code: "DM", value: "Dominica (DM)" },
      {
        name: "Dominican Republic",
        code: "DO",
        value: "Dominican Republic (DO)",
      },
      { name: "Ecuador", code: "EC", value: "Ecuador (EC)" },
      { name: "Egypt", code: "EG", value: "Egypt (EG)" },
      { name: "El Salvador", code: "SV", value: "El Salvador (SV)" },
      {
        name: "Equatorial Guinea",
        code: "GQ",
        value: "Equatorial Guinea (GQ)",
      },
      { name: "Eritrea", code: "ER", value: "Eritrea (ER)" },
      { name: "Estonia", code: "EE", value: "Estonia (EE)" },
      { name: "Ethiopia", code: "ET", value: "Ethiopia (ET)" },
      {
        name: "Falkland Islands (Malvinas)",
        code: "FK",
        value: "Falkland Islands (Malvinas) (FK)",
      },
      { name: "Faroe Islands", code: "FO", value: "Faroe Islands (FO)" },
      { name: "Fiji", code: "FJ", value: "Fiji (FJ)" },
      { name: "Finland", code: "FI", value: "Finland (FI)" },
      { name: "France", code: "FR", value: "France (FR)" },
      { name: "French Guiana", code: "GF", value: "French Guiana (GF)" },
      { name: "French Polynesia", code: "PF", value: "French Polynesia (PF)" },
      {
        name: "French Southern Territories",
        code: "TF",
        value: "French Southern Territories (TF)",
      },
      { name: "Gabon", code: "GA", value: "Gabon (GA)" },
      { name: "Gambia", code: "GM", value: "Gambia (GM)" },
      { name: "Georgia", code: "GE", value: "Georgia (GE)" },
      { name: "Germany", code: "DE", value: "Germany (DE)" },
      { name: "Ghana", code: "GH", value: "Ghana (GH)" },
      { name: "Gibraltar", code: "GI", value: "Gibraltar (GI)" },
      { name: "Greece", code: "GR", value: "Greece (GR)" },
      { name: "Greenland", code: "GL", value: "Greenland (GL)" },
      { name: "Grenada", code: "GD", value: "Grenada (GD)" },
      { name: "Guadeloupe", code: "GP", value: "Guadeloupe (GP)" },
      { name: "Guam", code: "GU", value: "Guam (GU)" },
      { name: "Guatemala", code: "GT", value: "Guatemala (GT)" },
      { name: "Guernsey", code: "GG", value: "Guernsey (GG)" },
      { name: "Guinea", code: "GN", value: "Guinea (GN)" },
      { name: "Guinea-Bissau", code: "GW", value: "Guinea-Bissau (GW)" },
      { name: "Guyana", code: "GY", value: "Guyana (GY)" },
      { name: "Haiti", code: "HT", value: "Haiti (HT)" },
      {
        name: "Heard Island and Mcdonald Islands",
        code: "HM",
        value: "Heard Island and Mcdonald Islands (HM)",
      },
      {
        name: "Holy See (Vatican City State)",
        code: "VA",
        value: "Holy See (Vatican City State) (VA)",
      },
      { name: "Honduras", code: "HN", value: "Honduras (HN)" },
      { name: "Hong Kong", code: "HK", value: "Hong Kong (HK)" },
      { name: "Hungary", code: "HU", value: "Hungary (HU)" },
      { name: "Iceland", code: "IS", value: "Iceland (IS)" },
      { name: "India", code: "IN", value: "India (IN)" },
      { name: "Indonesia", code: "ID", value: "Indonesia (ID)" },
      {
        name: "Iran, Islamic Republic Of",
        code: "IR",
        value: "Iran, Islamic Republic Of (IR)",
      },
      { name: "Iraq", code: "IQ", value: "Iraq (IQ)" },
      { name: "Ireland", code: "IE", value: "Ireland (IE)" },
      { name: "Isle of Man", code: "IM", value: "Isle of Man (IM)" },
      { name: "Israel", code: "IL", value: "Israel (IL)" },
      { name: "Italy", code: "IT", value: "Italy (IT)" },
      { name: "Jamaica", code: "JM", value: "Jamaica (JM)" },
      { name: "Japan", code: "JP", value: "Japan (JP)" },
      { name: "Jersey", code: "JE", value: "Jersey (JE)" },
      { name: "Jordan", code: "JO", value: "Jordan (JO)" },
      { name: "Kazakhstan", code: "KZ", value: "Kazakhstan (KZ)" },
      { name: "Kenya", code: "KE", value: "Kenya (KE)" },
      { name: "Kiribati", code: "KI", value: "Kiribati (KI)" },
      {
        name: `Korea, Democratic People'S Republic of`,
        code: "KP",
        value: `Korea, Democratic People'S Republic of (KP)`,
      },
      {
        name: "Korea, Republic of",
        code: "KR",
        value: "Korea, Republic of (KR)",
      },
      { name: "Kuwait", code: "KW", value: "Kuwait (KW)" },
      { name: "Kyrgyzstan", code: "KG", value: "Kyrgyzstan (KG)" },
      {
        name: `Lao People'S Democratic Republic`,
        code: "LA",
        value: `Lao People'S Democratic Republic (LA)`,
      },
      { name: "Latvia", code: "LV", value: "Latvia (LV)" },
      { name: "Lebanon", code: "LB", value: "Lebanon (LB)" },
      { name: "Lesotho", code: "LS", value: "Lesotho (LS)" },
      { name: "Liberia", code: "LR", value: "Liberia (LR)" },
      {
        name: "Libyan Arab Jamahiriya",
        code: "LY",
        value: "Libyan Arab Jamahiriya (LY)",
      },
      { name: "Liechtenstein", code: "LI", value: "Liechtenstein (LI)" },
      { name: "Lithuania", code: "LT", value: "Lithuania (LT)" },
      { name: "Luxembourg", code: "LU", value: "Luxembourg (LU)" },
      { name: "Macao", code: "MO", value: "Macao (MO)" },
      {
        name: "Macedonia, The Former Yugoslav Republic of",
        code: "MK",
        value: "Macedonia, The Former Yugoslav Republic of (MK)",
      },
      { name: "Madagascar", code: "MG", value: "Madagascar (MG)" },
      { name: "Malawi", code: "MW", value: "Malawi (MW)" },
      { name: "Malaysia", code: "MY", value: "Malaysia (MY)" },
      { name: "Maldives", code: "MV", value: "Maldives (MV)" },
      { name: "Mali", code: "ML", value: "Mali (ML)" },
      { name: "Malta", code: "MT", value: "Malta (MT)" },
      { name: "Marshall Islands", code: "MH", value: "Marshall Islands (MH)" },
      { name: "Martinique", code: "MQ", value: "Martinique (MQ)" },
      { name: "Mauritania", code: "MR", value: "Mauritania (MR)" },
      { name: "Mauritius", code: "MU", value: "Mauritius (MU)" },
      { name: "Mayotte", code: "YT", value: "Mayotte (YT)" },
      { name: "Mexico", code: "MX", value: "Mexico (MX)" },
      {
        name: "Micronesia, Federated States of",
        code: "FM",
        value: "Micronesia, Federated States of (FM)",
      },
      {
        name: "Moldova, Republic of",
        code: "MD",
        value: "Moldova, Republic of (MD)",
      },
      { name: "Monaco", code: "MC", value: "Monaco (MC)" },
      { name: "Mongolia", code: "MN", value: "Mongolia (MN)" },
      { name: "Montserrat", code: "MS", value: "Montserrat (MS)" },
      { name: "Morocco", code: "MA", value: "Morocco (MA)" },
      { name: "Mozambique", code: "MZ", value: "Mozambique (MZ)" },
      { name: "Myanmar", code: "MM", value: "Myanmar (MM)" },
      { name: "Namibia", code: "NA", value: "Namibia (NA)" },
      { name: "Nauru", code: "NR", value: "Nauru (NR)" },
      { name: "Nepal", code: "NP", value: "Nepal (NP)" },
      { name: "Netherlands", code: "NL", value: "Netherlands (NL)" },
      {
        name: "Netherlands Antilles",
        code: "AN",
        value: "Netherlands Antilles (AN)",
      },
      { name: "New Caledonia", code: "NC", value: "New Caledonia (NC)" },
      { name: "New Zealand", code: "NZ", value: "New Zealand (NZ)" },
      { name: "Nicaragua", code: "NI", value: "Nicaragua (NI)" },
      { name: "Niger", code: "NE", value: "Niger (NE)" },
      { name: "Nigeria", code: "NG", value: "Nigeria (NG)" },
      { name: "Niue", code: "NU", value: "Niue (NU)" },
      { name: "Norfolk Island", code: "NF", value: "Norfolk Island (NF)" },
      {
        name: "Northern Mariana Islands",
        code: "MP",
        value: "Northern Mariana Islands (MP)",
      },
      { name: "Norway", code: "NO", value: "Norway (NO)" },
      { name: "Oman", code: "OM", value: "Oman (OM)" },
      { name: "Pakistan", code: "PK", value: "Pakistan (PK)" },
      { name: "Palau", code: "PW", value: "Palau (PW)" },
      {
        name: "Palestinian Territory, Occupied",
        code: "PS",
        value: "Palestinian Territory, Occupied (PS)",
      },
      { name: "Panama", code: "PA", value: "Panama (PA)" },
      { name: "Papua New Guinea", code: "PG", value: "Papua New Guinea (PG)" },
      { name: "Paraguay", code: "PY", value: "Paraguay (PY)" },
      { name: "Peru", code: "PE", value: "Peru (PE)" },
      { name: "Philippines", code: "PH", value: "Philippines (PH)" },
      { name: "Pitcairn", code: "PN", value: "Pitcairn (PN)" },
      { name: "Poland", code: "PL", value: "Poland (PL)" },
      { name: "Portugal", code: "PT", value: "Portugal (PT)" },
      { name: "Puerto Rico", code: "PR", value: "Puerto Rico (PR)" },
      { name: "Qatar", code: "QA", value: "Qatar (QA)" },
      { name: "Reunion", code: "RE", value: "Reunion (RE)" },
      { name: "Romania", code: "RO", value: "Romania (RO)" },
      {
        name: "Russian Federation",
        code: "RU",
        value: "Russian Federation (RU)",
      },
      { name: "RWANDA", code: "RW", value: "RWANDA (RW)" },
      { name: "Saint Helena", code: "SH", value: "Saint Helena (SH)" },
      {
        name: "Saint Kitts and Nevis",
        code: "KN",
        value: "Saint Kitts and Nevis (KN)",
      },
      { name: "Saint Lucia", code: "LC", value: "Saint Lucia (LC)" },
      {
        name: "Saint Pierre and Miquelon",
        code: "PM",
        value: "Saint Pierre and Miquelon (PM)",
      },
      {
        name: "Saint Vincent and the Grenadines",
        code: "VC",
        value: "Saint Vincent and the Grenadines (VC)",
      },
      { name: "Samoa", code: "WS", value: "Samoa (WS)" },
      { name: "San Marino", code: "SM", value: "San Marino (SM)" },
      {
        name: "Sao Tome and Principe",
        code: "ST",
        value: "Sao Tome and Principe (ST)",
      },
      { name: "Saudi Arabia", code: "SA", value: "Saudi Arabia (SA)" },
      { name: "Senegal", code: "SN", value: "Senegal (SN)" },
      {
        name: "Serbia and Montenegro",
        code: "CS",
        value: "Serbia and Montenegro (CS)",
      },
      { name: "Seychelles", code: "SC", value: "Seychelles (SC)" },
      { name: "Sierra Leone", code: "SL", value: "Sierra Leone (SL)" },
      { name: "Singapore", code: "SG", value: "Singapore (SG)" },
      { name: "Slovakia", code: "SK", value: "Slovakia (SK)" },
      { name: "Slovenia", code: "SI", value: "Slovenia (SI)" },
      { name: "Solomon Islands", code: "SB", value: "Solomon Islands (SB)" },
      { name: "Somalia", code: "SO", value: "Somalia (SO)" },
      { name: "South Africa", code: "ZA", value: "South Africa (ZA)" },
      {
        name: "South Georgia and the South Sandwich Islands",
        code: "GS",
        value: "South Georgia and the South Sandwich Islands (GS)",
      },
      { name: "Spain", code: "ES", value: "Spain (ES)" },
      { name: "Sri Lanka", code: "LK", value: "Sri Lanka (LK)" },
      { name: "Sudan", code: "SD", value: "Sudan (SD)" },
      { name: "Suriname", code: "SR", value: "Suriname (SR)" },
      {
        name: "Svalbard and Jan Mayen",
        code: "SJ",
        value: "Svalbard and Jan Mayen (SJ)",
      },
      { name: "Swaziland", code: "SZ", value: "Swaziland (SZ)" },
      { name: "Sweden", code: "SE", value: "Sweden (SE)" },
      { name: "Switzerland", code: "CH", value: "Switzerland (CH)" },
      {
        name: "Syrian Arab Republic",
        code: "SY",
        value: "Syrian Arab Republic (SY)",
      },
      {
        name: "Taiwan, Province of China",
        code: "TW",
        value: "Taiwan, Province of China (TW)",
      },
      { name: "Tajikistan", code: "TJ", value: "Tajikistan (TJ)" },
      {
        name: "Tanzania, United Republic of",
        code: "TZ",
        value: "Tanzania, United Republic of (TZ)",
      },
      { name: "Thailand", code: "TH", value: "Thailand (TH)" },
      { name: "Timor-Leste", code: "TL", value: "Timor-Leste (TL)" },
      { name: "Togo", code: "TG", value: "Togo (TG)" },
      { name: "Tokelau", code: "TK", value: "Tokelau (TK)" },
      { name: "Tonga", code: "TO", value: "Tonga (TO)" },
      {
        name: "Trinidad and Tobago",
        code: "TT",
        value: "Trinidad and Tobago (TT)",
      },
      { name: "Tunisia", code: "TN", value: "Tunisia (TN)" },
      { name: "Turkey", code: "TR", value: "Turkey (TR)" },
      { name: "Turkmenistan", code: "TM", value: "Turkmenistan (TM)" },
      {
        name: "Turks and Caicos Islands",
        code: "TC",
        value: "Turks and Caicos Islands (TC)",
      },
      { name: "Tuvalu", code: "TV", value: "Tuvalu (TV)" },
      { name: "Uganda", code: "UG", value: "Uganda (UG)" },
      { name: "Ukraine", code: "UA", value: "Ukraine (UA)" },
      {
        name: "United Arab Emirates",
        code: "AE",
        value: "United Arab Emirates (AE)",
      },
      { name: "United Kingdom", code: "GB", value: "United Kingdom (GB)" },
      { name: "United States", code: "US", value: "United States (US)" },
      {
        name: "United States Minor Outlying Islands",
        code: "UM",
        value: "United States Minor Outlying Islands (UM)",
      },
      { name: "Uruguay", code: "UY", value: "Uruguay (UY)" },
      { name: "Uzbekistan", code: "UZ", value: "Uzbekistan (UZ)" },
      { name: "Vanuatu", code: "VU", value: "Vanuatu (VU)" },
      { name: "Venezuela", code: "VE", value: "Venezuela (VE)" },
      { name: "Viet Nam", code: "VN", value: "Viet Nam (VN)" },
      {
        name: "Virgin Islands, British",
        code: "VG",
        value: "Virgin Islands, British (VG)",
      },
      {
        name: "Virgin Islands, U.S.",
        code: "VI",
        value: "Virgin Islands, U.S. (VI)",
      },
      {
        name: "Wallis and Futuna",
        code: "WF",
        value: "Wallis and Futuna (WF)",
      },
      { name: "Western Sahara", code: "EH", value: "Western Sahara (EH)" },
      { name: "Yemen", code: "YE", value: "Yemen (YE)" },
      { name: "Zambia", code: "ZM", value: "Zambia (ZM)" },
      { name: "Zimbabwe", code: "ZW", value: "Zimbabwe (ZW)" },
    ],
    currencyListing: [
      // The listing of all the currencies
      {
        country: "Afghanistan",
        currency_code: "AFN",
        country_code: "AF",
        currency_name: "Afghan Afghani",
        value: "AFN (Afghan Afghani)",
      },
      {
        country: "Albania",
        currency_code: "ALL",
        country_code: "AL",
        currency_name: "Albanian Lek",
        value: "ALL (Albanian Lek)",
      },
      {
        country: "Algeria",
        currency_code: "DZD",
        country_code: "DZ",
        currency_name: "Algerian Dinar",
        value: "DZD (Algerian Dinar)",
      },
      {
        country: "American Samoa",
        currency_code: "USD",
        country_code: "AS",
        currency_name: "US Dollar",
        value: "USD (US Dollar)",
      },
      {
        country: "Andorra",
        currency_code: "EUR",
        country_code: "",
        currency_name: "Euro",
        value: "EUR (Euro)",
      },
      // { country: 'Angola', currency_code: 'AOA', country_code: 'AO', currency_name: '', value: 'AOA ()' },
      // { country: 'Anguilla', currency_code: 'XCD', country_code: 'AI', currency_name: '', value: 'XCD ()' },
      // { country: 'Antarctica', currency_code: 'XCD', country_code: 'AQ', currency_name: '', value: 'XCD ()' },
      // { country: 'Antigua and Barbuda', currency_code: 'XCD', country_code: 'AG', currency_name: '', value: 'XCD ()' },
      {
        country: "Argentina",
        currency_code: "ARS",
        country_code: "AR",
        currency_name: "Argentine Peso",
        value: "ARS (Argentine Peso)",
      },
      {
        country: "Armenia",
        currency_code: "AMD",
        country_code: "AM",
        currency_name: "Armenian Dram",
        value: "AMD (Armenian Dram)",
      },
      // { country: 'Aruba', currency_code: 'AWG', country_code: 'AW', currency_name: '', value: 'AWG ()' },
      {
        country: "Australia",
        currency_code: "AUD",
        country_code: "AU",
        currency_name: "Australian Dollar",
        value: "AUD (Australian Dollar)",
      },
      {
        country: "Austria",
        currency_code: "EUR",
        country_code: "AT",
        currency_name: "Euro",
        value: "EUR (Euro)",
      },
      {
        country: "Azerbaijan",
        currency_code: "AZN",
        country_code: "AZ",
        currency_name: "Azerbaijani Manat",
        value: "AZN (Azerbaijani Manat)",
      },
      // { country: 'Bahamas', currency_code: 'BSD', country_code: 'BS', currency_name: '', value: 'BSD ()' },
      {
        country: "Bahrain",
        currency_code: "BHD",
        country_code: "BH",
        currency_name: "Bahraini Dinar",
        value: "BHD (Bahraini Dinar)",
      },
      {
        country: "Bangladesh",
        currency_code: "BDT",
        country_code: "BD",
        currency_name: "Bangladeshi Taka",
        value: "BDT (Bangladeshi Taka)",
      },
      // { country: 'Barbados', currency_code: 'BBD', country_code: 'BB', currency_name: '', value: 'BBD ()' },
      // { country: 'Belarus', currency_code: 'BYR', country_code: 'BY', currency_name: '', value: 'BYR ()' },
      {
        country: "Belgium",
        currency_code: "EUR",
        country_code: "BE",
        currency_name: "Euro",
        value: "EUR (Euro)",
      },
      {
        country: "Belize",
        currency_code: "BZD",
        country_code: "BZ",
        currency_name: "Belize Dollar",
        value: "BZD (Belize Dollar)",
      },
      {
        country: "Benin",
        currency_code: "XOF",
        country_code: "BJ",
        currency_name: "CFA Franc BCEAO",
        value: "XOF (CFA Franc BCEAO)",
      },
      // { country: 'Bermuda', currency_code: 'BMD', country_code: 'BM', currency_name: '', value: 'BMD ()' },
      // { country: 'Bhutan', currency_code: 'BTN', country_code: 'BT', currency_name: '', value: 'BTN ()' },
      {
        country: "Bolivia",
        currency_code: "BOB",
        country_code: "BO",
        currency_name: "Bolivian Boliviano",
        value: "BOB (Bolivian Boliviano)",
      },
      {
        country: "Bosnia and Herzegovina",
        currency_code: "BAM",
        country_code: "BA",
        currency_name: "Bosnia-Herzegovina Convertible Mark",
        value: "BAM (Bosnia-Herzegovina Convertible Mark)",
      },
      {
        country: "Botswana",
        currency_code: "BWP",
        country_code: "BW",
        currency_name: "Botswanan Pula",
        value: "BWP (Botswanan Pula)",
      },
      {
        country: "Bouvet Island",
        currency_code: "NOK",
        country_code: "BV",
        currency_name: "Norwegian Krone",
        value: "NOK (Norwegian Krone)",
      },
      {
        country: "Brazil",
        currency_code: "BRL",
        country_code: "BR",
        currency_name: "Brazilian Real",
        value: "BRL (Brazilian Real)",
      },
      {
        country: "British Indian Ocean Territory",
        currency_code: "USD",
        country_code: "IO",
        currency_name: "US Dollar",
        value: "USD (US Dollar)",
      },
      {
        country: "Brunei",
        currency_code: "BND",
        country_code: "",
        currency_name: "Brunei Dollar",
        value: "BND (Brunei Dollar)",
      },
      {
        country: "Bulgaria",
        currency_code: "BGN",
        country_code: "BG",
        currency_name: "Bulgarian Lev",
        value: "BGN (Bulgarian Lev)",
      },
      {
        country: "Burkina Faso",
        currency_code: "XOF",
        country_code: "BF",
        currency_name: "CFA Franc BCEAO",
        value: "XOF (CFA Franc BCEAO)",
      },
      {
        country: "Burundi",
        currency_code: "BIF",
        country_code: "BI",
        currency_name: "Burundian Franc",
        value: "BIF (Burundian Franc)",
      },
      {
        country: "Cambodia",
        currency_code: "KHR",
        country_code: "KH",
        currency_name: "Cambodian Riel",
        value: "KHR (Cambodian Riel)",
      },
      {
        country: "Cameroon",
        currency_code: "XAF",
        country_code: "CM",
        currency_name: "CFA Franc BEAC",
        value: "XAF (CFA Franc BEAC)",
      },
      {
        country: "Canada",
        currency_code: "CAD",
        country_code: "CA",
        currency_name: "Canadian Dollar",
        value: "CAD (Canadian Dollar)",
      },
      {
        country: "Cape Verde",
        currency_code: "CVE",
        country_code: "CV",
        currency_name: "Cape Verdean Escudo",
        value: "CVE (Cape Verdean Escudo)",
      },
      // { country: 'Cayman Islands', currency_code: 'KYD', country_code: 'KY', currency_name: '', value: 'KYD ()' },
      {
        country: "Central African Republic",
        currency_code: "XAF",
        country_code: "CF",
        currency_name: "CFA Franc BEAC",
        value: "XAF (CFA Franc BEAC)",
      },
      {
        country: "Chad",
        currency_code: "XAF",
        country_code: "TD",
        currency_name: "CFA Franc BEAC",
        value: "XAF (CFA Franc BEAC)",
      },
      {
        country: "Chile",
        currency_code: "CLP",
        country_code: "CL",
        currency_name: "Chilean Peso",
        value: "CLP (Chilean Peso)",
      },
      {
        country: "China",
        currency_code: "CNY",
        country_code: "CN",
        currency_name: "Chinese Yuan",
        value: "CNY (Chinese Yuan)",
      },
      {
        country: "Christmas Island",
        currency_code: "AUD",
        country_code: "CX",
        currency_name: "Australian Dollar",
        value: "AUD (Australian Dollar)",
      },
      {
        country: "Cocos (Keeling) Islands",
        currency_code: "AUD",
        country_code: "CC",
        currency_name: "Australian Dollar",
        value: "AUD (Australian Dollar)",
      },
      {
        country: "Colombia",
        currency_code: "COP",
        country_code: "CO",
        currency_name: "Colombian Peso",
        value: "COP (Colombian Peso)",
      },
      {
        country: "Comoros",
        currency_code: "KMF",
        country_code: "KM",
        currency_name: "Comorian Franc",
        value: "KMF (Comorian Franc)",
      },
      {
        country: "Congo",
        currency_code: "XAF",
        country_code: "CG",
        currency_name: "CFA Franc BEAC",
        value: "XAF (CFA Franc BEAC)",
      },
      {
        country: "Cook Islands",
        currency_code: "NZD",
        country_code: "CK",
        currency_name: "New Zealand Dollar",
        value: "NZD (New Zealand Dollar)",
      },
      {
        country: "Costa Rica",
        currency_code: "CRC",
        country_code: "CR",
        currency_name: "Costa Rican Colón",
        value: "CRC (Costa Rican Colón)",
      },
      {
        country: "Croatia",
        currency_code: "EUR",
        country_code: "HR",
        currency_name: "Euro",
        value: "EUR (Euro)",
      },
      // { country: 'Cuba', currency_code: 'CUP', country_code: 'CU', currency_name: '', value: 'CUP ()' },
      {
        country: "Cyprus",
        currency_code: "EUR",
        country_code: "CY",
        currency_name: "Euro",
        value: "EUR (Euro)",
      },
      {
        country: "Czech Republic",
        currency_code: "CZK",
        country_code: "CZ",
        currency_name: "Czech Republic Koruna",
        value: "CZK (Czech Republic Koruna)",
      },
      {
        country: "Denmark",
        currency_code: "DKK",
        country_code: "DK",
        currency_name: "Danish Krone",
        value: "DKK (Danish Krone)",
      },
      {
        country: "Djibouti",
        currency_code: "DJF",
        country_code: "DJ",
        currency_name: "Djiboutian Franc",
        value: "DJF (Djiboutian Franc)",
      },
      // { country: 'Dominica', currency_code: 'XCD', country_code: 'DM', currency_name: '', value: 'XCD ()' },
      {
        country: "Dominican Republic",
        currency_code: "DOP",
        country_code: "DO",
        currency_name: "Dominican Peso",
        value: "DOP (Dominican Peso)",
      },
      {
        country: "East Timor",
        currency_code: "USD",
        country_code: "",
        currency_name: "US Dollar",
        value: "USD (US Dollar)",
      },
      // { country: 'Ecuador', currency_code: 'ECS', country_code: 'EC', currency_name: '', value: 'ECS ()' },
      {
        country: "Egypt",
        currency_code: "EGP",
        country_code: "EG",
        currency_name: "Egyptian Pound",
        value: "EGP (Egyptian Pound)",
      },
      // { country: 'El Salvador', currency_code: 'SVC', country_code: 'SV', currency_name: '', value: 'SVC ()' },
      {
        country: "England",
        currency_code: "GBP",
        country_code: "",
        currency_name: "British Pound Sterling",
        value: "GBP (British Pound Sterling)",
      },
      {
        country: "Equatorial Guinea",
        currency_code: "XAF",
        country_code: "GQ",
        currency_name: "CFA Franc BEAC",
        value: "XAF (CFA Franc BEAC)",
      },
      {
        country: "Eritrea",
        currency_code: "ERN",
        country_code: "ER",
        currency_name: "Eritrean Nakfa",
        value: "ERN (Eritrean Nakfa)",
      },
      {
        country: "Estonia",
        currency_code: "EUR",
        country_code: "EE",
        currency_name: "Euro",
        value: "EUR (Euro)",
      },
      // { country: 'Eswatini', currency_code: 'SZL', country_code: '', currency_name: '', value: 'SZL ()' },
      {
        country: "Ethiopia",
        currency_code: "ETB",
        country_code: "ET",
        currency_name: "Ethiopian Birr",
        value: "ETB (Ethiopian Birr)",
      },
      // { country: 'Falkland Islands', currency_code: 'FKP', country_code: '', currency_name: '', value: 'FKP ()' },
      {
        country: "Faroe Islands",
        currency_code: "DKK",
        country_code: "FO",
        currency_name: "Danish Krone",
        value: "DKK (Danish Krone)",
      },
      // { country: 'Fiji Islands', currency_code: 'FJD', country_code: '', currency_name: '', value: 'FJD ()' },
      {
        country: "Finland",
        currency_code: "EUR",
        country_code: "FI",
        currency_name: "Euro",
        value: "EUR (Euro)",
      },
      {
        country: "France",
        currency_code: "EUR",
        country_code: "FR",
        currency_name: "Euro",
        value: "EUR (Euro)",
      },
      {
        country: "French Guiana",
        currency_code: "EUR",
        country_code: "GF",
        currency_name: "Euro",
        value: "EUR (Euro)",
      },
      // { country: 'French Polynesia', currency_code: 'XPF', country_code: 'PF', currency_name: '', value: 'XPF ()' },
      {
        country: "French Southern territories",
        currency_code: "EUR",
        country_code: "",
        currency_name: "Euro",
        value: "EUR (Euro)",
      },
      {
        country: "Gabon",
        currency_code: "XAF",
        country_code: "GA",
        currency_name: "CFA Franc BEAC",
        value: "XAF (CFA Franc BEAC)",
      },
      // { country: 'Gambia', currency_code: 'GMD', country_code: 'GM', currency_name: '', value: 'GMD ()' },
      {
        country: "Georgia",
        currency_code: "GEL",
        country_code: "GE",
        currency_name: "Georgian Lari",
        value: "GEL (Georgian Lari)",
      },
      {
        country: "Germany",
        currency_code: "EUR",
        country_code: "DE",
        currency_name: "Euro",
        value: "EUR (Euro)",
      },
      {
        country: "Ghana",
        currency_code: "GHS",
        country_code: "GH",
        currency_name: "Ghanaian Cedi",
        value: "GHS (Ghanaian Cedi)",
      },
      // { country: 'Gibraltar', currency_code: 'GIP', country_code: 'GI', currency_name: '', value: 'GIP ()' },
      {
        country: "Greece",
        currency_code: "EUR",
        country_code: "GR",
        currency_name: "Euro",
        value: "EUR (Euro)",
      },
      {
        country: "Greenland",
        currency_code: "DKK",
        country_code: "GL",
        currency_name: "Danish Krone",
        value: "DKK (Danish Krone)",
      },
      // { country: 'Grenada', currency_code: 'XCD', country_code: 'GD', currency_name: '', value: 'XCD ()' },
      {
        country: "Guadeloupe",
        currency_code: "EUR",
        country_code: "GP",
        currency_name: "Euro",
        value: "EUR (Euro)",
      },
      {
        country: "Guam",
        currency_code: "USD",
        country_code: "GU",
        currency_name: "US Dollar",
        value: "USD (US Dollar)",
      },
      // { country: 'Guatemala', currency_code: 'QTQ', country_code: 'GT', currency_name: '', value: 'QTQ ()' },
      {
        country: "Guinea",
        currency_code: "GNF",
        country_code: "GN",
        currency_name: "Guinean Franc",
        value: "GNF (Guinean Franc)",
      },
      // { country: 'Guinea-Bissau', currency_code: 'CFA', country_code: 'GW', currency_name: '', value: 'CFA ()' },
      // { country: 'Guyana', currency_code: 'GYD', country_code: 'GY', currency_name: '', value: 'GYD ()' },
      // { country: 'Haiti', currency_code: 'HTG', country_code: 'HT', currency_name: '', value: 'HTG ()' },
      {
        country: "Heard Island and McDonald Islands",
        currency_code: "AUD",
        country_code: "",
        currency_name: "Australian Dollar",
        value: "AUD (Australian Dollar)",
      },
      {
        country: "Holy See (Vatican City State)",
        currency_code: "EUR",
        country_code: "VA",
        currency_name: "Euro",
        value: "EUR (Euro)",
      },
      {
        country: "Honduras",
        currency_code: "HNL",
        country_code: "HN",
        currency_name: "Honduran Lempira",
        value: "HNL (Honduran Lempira)",
      },
      {
        country: "Hong Kong",
        currency_code: "HKD",
        country_code: "HK",
        currency_name: "Hong Kong Dollar",
        value: "HKD (Hong Kong Dollar)",
      },
      {
        country: "Hungary",
        currency_code: "HUF",
        country_code: "HU",
        currency_name: "Hungarian Forint",
        value: "HUF (Hungarian Forint)",
      },
      {
        country: "Iceland",
        currency_code: "ISK",
        country_code: "IS",
        currency_name: "Icelandic Króna",
        value: "ISK (Icelandic Króna)",
      },
      {
        country: "India",
        currency_code: "INR",
        country_code: "IN",
        currency_name: "Indian Rupee",
        value: "INR (Indian Rupee)",
      },
      {
        country: "Indonesia",
        currency_code: "IDR",
        country_code: "ID",
        currency_name: "Indonesian Rupiah",
        value: "IDR (Indonesian Rupiah)",
      },
      {
        country: "Iran",
        currency_code: "IRR",
        country_code: "",
        currency_name: "Iranian Rial",
        value: "IRR (Iranian Rial)",
      },
      {
        country: "Iraq",
        currency_code: "IQD",
        country_code: "IQ",
        currency_name: "Iraqi Dinar",
        value: "IQD (Iraqi Dinar)",
      },
      {
        country: "Ireland",
        currency_code: "EUR",
        country_code: "IE",
        currency_name: "Euro",
        value: "EUR (Euro)",
      },
      {
        country: "Israel",
        currency_code: "ILS",
        country_code: "IL",
        currency_name: "Israeli New Sheqel",
        value: "ILS (Israeli New Sheqel)",
      },
      {
        country: "Italy",
        currency_code: "EUR",
        country_code: "IT",
        currency_name: "Euro",
        value: "EUR (Euro)",
      },
      {
        country: "Ivory Coast",
        currency_code: "XOF",
        country_code: "",
        currency_name: "CFA Franc BCEAO",
        value: "XOF (CFA Franc BCEAO)",
      },
      {
        country: "Jamaica",
        currency_code: "JMD",
        country_code: "JM",
        currency_name: "Jamaican Dollar",
        value: "JMD (Jamaican Dollar)",
      },
      {
        country: "Japan",
        currency_code: "JPY",
        country_code: "JP",
        currency_name: "Japanese Yen",
        value: "JPY (Japanese Yen)",
      },
      {
        country: "Jordan",
        currency_code: "JOD",
        country_code: "JO",
        currency_name: "Jordanian Dinar",
        value: "JOD (Jordanian Dinar)",
      },
      {
        country: "Kazakhstan",
        currency_code: "KZT",
        country_code: "KZ",
        currency_name: "Kazakhstani Tenge",
        value: "KZT (Kazakhstani Tenge)",
      },
      {
        country: "Kenya",
        currency_code: "KES",
        country_code: "KE",
        currency_name: "Kenyan Shilling",
        value: "KES (Kenyan Shilling)",
      },
      {
        country: "Kiribati",
        currency_code: "AUD",
        country_code: "KI",
        currency_name: "Australian Dollar",
        value: "AUD (Australian Dollar)",
      },
      {
        country: "Kuwait",
        currency_code: "KWD",
        country_code: "KW",
        currency_name: "Kuwaiti Dinar",
        value: "KWD (Kuwaiti Dinar)",
      },
      // { country: 'Kyrgyzstan', currency_code: 'KGS', country_code: 'KG', currency_name: '', value: 'KGS ()' },
      // { country: 'Laos', currency_code: 'LAK', country_code: '', currency_name: '', value: 'LAK ()' },
      {
        country: "Latvia",
        currency_code: "EUR",
        country_code: "LV",
        currency_name: "Euro",
        value: "EUR (Euro)",
      },
      {
        country: "Lebanon",
        currency_code: "LBP",
        country_code: "LB",
        currency_name: "Lebanese Pound",
        value: "LBP (Lebanese Pound)",
      },
      // { country: 'Lesotho', currency_code: 'LSL', country_code: 'LS', currency_name: '', value: 'LSL ()' },
      // { country: 'Liberia', currency_code: 'LRD', country_code: 'LR', currency_name: '', value: 'LRD ()' },
      {
        country: "Libya",
        currency_code: "LYD",
        country_code: "",
        currency_name: "Libyan Dinar",
        value: "LYD (Libyan Dinar)",
      },
      {
        country: "Liechtenstein",
        currency_code: "CHF",
        country_code: "LI",
        currency_name: "Swiss Franc",
        value: "CHF (Swiss Franc)",
      },
      {
        country: "Lithuania",
        currency_code: "EUR",
        country_code: "LT",
        currency_name: "Euro",
        value: "EUR (Euro)",
      },
      {
        country: "Luxembourg",
        currency_code: "EUR",
        country_code: "LU",
        currency_name: "Euro",
        value: "EUR (Euro)",
      },
      {
        country: "Macau",
        currency_code: "MOP",
        country_code: "",
        currency_name: "Macanese Pataca",
        value: "MOP (Macanese Pataca)",
      },
      {
        country: "North Macedonia",
        currency_code: "MKD",
        country_code: "",
        currency_name: "Macedonian Denar",
        value: "MKD (Macedonian Denar)",
      },
      // { country: 'Madagascar', currency_code: 'MGF', country_code: 'MG', currency_name: '', value: 'MGF ()' },
      // { country: 'Malawi', currency_code: 'MWK', country_code: 'MW', currency_name: '', value: 'MWK ()' },
      {
        country: "Malaysia",
        currency_code: "MYR",
        country_code: "MY",
        currency_name: "Malaysian Ringgit",
        value: "MYR (Malaysian Ringgit)",
      },
      // { country: 'Maldives', currency_code: 'MVR', country_code: 'MV', currency_name: '', value: 'MVR ()' },
      {
        country: "Mali",
        currency_code: "XOF",
        country_code: "ML",
        currency_name: "CFA Franc BCEAO",
        value: "XOF (CFA Franc BCEAO)",
      },
      {
        country: "Malta",
        currency_code: "EUR",
        country_code: "MT",
        currency_name: "Euro",
        value: "EUR (Euro)",
      },
      {
        country: "Marshall Islands",
        currency_code: "USD",
        country_code: "MH",
        currency_name: "US Dollar",
        value: "USD (US Dollar)",
      },
      {
        country: "Martinique",
        currency_code: "EUR",
        country_code: "MQ",
        currency_name: "Euro",
        value: "EUR (Euro)",
      },
      // { country: 'Mauritania', currency_code: 'MRO', country_code: 'MR', currency_name: '', value: 'MRO ()' },
      {
        country: "Mauritius",
        currency_code: "MUR",
        country_code: "MU",
        currency_name: "Mauritian Rupee",
        value: "MUR (Mauritian Rupee)",
      },
      {
        country: "Mayotte",
        currency_code: "EUR",
        country_code: "YT",
        currency_name: "Euro",
        value: "EUR (Euro)",
      },
      {
        country: "Mexico",
        currency_code: "MXN",
        country_code: "MX",
        currency_name: "Mexican Peso",
        value: "MXN (Mexican Peso)",
      },
      {
        country: "Micronesia, Federated States of",
        currency_code: "USD",
        country_code: "FM",
        currency_name: "US Dollar",
        value: "USD (US Dollar)",
      },
      {
        country: "Moldova",
        currency_code: "MDL",
        country_code: "",
        currency_name: "Moldovan Leu",
        value: "MDL (Moldovan Leu)",
      },
      {
        country: "Monaco",
        currency_code: "EUR",
        country_code: "MC",
        currency_name: "Euro",
        value: "EUR (Euro)",
      },
      // { country: 'Mongolia', currency_code: 'MNT', country_code: 'MN', currency_name: '', value: 'MNT ()' },
      // { country: 'Montserrat', currency_code: 'XCD', country_code: 'MS', currency_name: '', value: 'XCD ()' },
      {
        country: "Morocco",
        currency_code: "MAD",
        country_code: "MA",
        currency_name: "Moroccan Dirham",
        value: "MAD (Moroccan Dirham)",
      },
      {
        country: "Mozambique",
        currency_code: "MZN",
        country_code: "MZ",
        currency_name: "Mozambican Metical",
        value: "MZN (Mozambican Metical)",
      },
      // { country: 'Myanmar', currency_code: 'MMR', country_code: 'MM', currency_name: '', value: 'MMR ()' },
      {
        country: "Namibia",
        currency_code: "NAD",
        country_code: "NA",
        currency_name: "Namibian Dollar",
        value: "NAD (Namibian Dollar)",
      },
      {
        country: "Nauru",
        currency_code: "AUD",
        country_code: "NR",
        currency_name: "Australian Dollar",
        value: "AUD (Australian Dollar)",
      },
      {
        country: "Nepal",
        currency_code: "NPR",
        country_code: "NP",
        currency_name: "Nepalese Rupee",
        value: "NPR (Nepalese Rupee)",
      },
      {
        country: "Netherlands",
        currency_code: "EUR",
        country_code: "NL",
        currency_name: "Euro",
        value: "EUR (Euro)",
      },
      // { country: 'Netherlands Antilles', currency_code: 'ANG', country_code: 'AN', currency_name: '', value: 'ANG ()' },
      // { country: 'New Caledonia', currency_code: 'XPF', country_code: 'NC', currency_name: '', value: 'XPF ()' },
      {
        country: "New Zealand",
        currency_code: "NZD",
        country_code: "NZ",
        currency_name: "New Zealand Dollar",
        value: "NZD (New Zealand Dollar)",
      },
      {
        country: "Nicaragua",
        currency_code: "NIO",
        country_code: "NI",
        currency_name: "Nicaraguan Córdoba",
        value: "NIO (Nicaraguan Córdoba)",
      },
      {
        country: "Niger",
        currency_code: "XOF",
        country_code: "NE",
        currency_name: "CFA Franc BCEAO",
        value: "XOF (CFA Franc BCEAO)",
      },
      {
        country: "Nigeria",
        currency_code: "NGN",
        country_code: "NG",
        currency_name: "Nigerian Naira",
        value: "NGN (Nigerian Naira)",
      },
      {
        country: "Niue",
        currency_code: "NZD",
        country_code: "NU",
        currency_name: "New Zealand Dollar",
        value: "NZD (New Zealand Dollar)",
      },
      {
        country: "Norfolk Island",
        currency_code: "AUD",
        country_code: "NF",
        currency_name: "Australian Dollar",
        value: "AUD (Australian Dollar)",
      },
      // { country: 'North Korea', currency_code: 'KPW', country_code: '', currency_name: '', value: 'KPW ()' },
      {
        country: "Northern Ireland",
        currency_code: "GBP",
        country_code: "",
        currency_name: "British Pound Sterling",
        value: "GBP (British Pound Sterling)",
      },
      {
        country: "Northern Mariana Islands",
        currency_code: "USD",
        country_code: "MP",
        currency_name: "US Dollar",
        value: "USD (US Dollar)",
      },
      {
        country: "Norway",
        currency_code: "NOK",
        country_code: "NO",
        currency_name: "Norwegian Krone",
        value: "NOK (Norwegian Krone)",
      },
      {
        country: "Oman",
        currency_code: "OMR",
        country_code: "OM",
        currency_name: "Omani Rial",
        value: "OMR (Omani Rial)",
      },
      {
        country: "Pakistan",
        currency_code: "PKR",
        country_code: "PK",
        currency_name: "Pakistani Rupee",
        value: "PKR (Pakistani Rupee)",
      },
      {
        country: "Palau",
        currency_code: "USD",
        country_code: "PW",
        currency_name: "US Dollar",
        value: "USD (US Dollar)",
      },
      // { country: 'Palestine', currency_code: null, country_code: '', currency_name: '', value: 'null ()' },
      {
        country: "Panama",
        currency_code: "PAB",
        country_code: "PA",
        currency_name: "Panamanian Balboa",
        value: "PAB (Panamanian Balboa)",
      },
      // { country: 'Papua New Guinea', currency_code: 'PGK', country_code: 'PG', currency_name: '', value: 'PGK ()' },
      {
        country: "Paraguay",
        currency_code: "PYG",
        country_code: "PY",
        currency_name: "Paraguayan Guarani",
        value: "PYG (Paraguayan Guarani)",
      },
      {
        country: "Peru",
        currency_code: "PEN",
        country_code: "PE",
        currency_name: "Peruvian Nuevo Sol",
        value: "PEN (Peruvian Nuevo Sol)",
      },
      {
        country: "Philippines",
        currency_code: "PHP",
        country_code: "PH",
        currency_name: "Philippine Peso",
        value: "PHP (Philippine Peso)",
      },
      {
        country: "Pitcairn Islands",
        currency_code: "NZD",
        country_code: "",
        currency_name: "New Zealand Dollar",
        value: "NZD (New Zealand Dollar)",
      },
      {
        country: "Poland",
        currency_code: "PLN",
        country_code: "PL",
        currency_name: "Polish Zloty",
        value: "PLN (Polish Zloty)",
      },
      {
        country: "Portugal",
        currency_code: "EUR",
        country_code: "PT",
        currency_name: "Euro",
        value: "EUR (Euro)",
      },
      {
        country: "Puerto Rico",
        currency_code: "USD",
        country_code: "PR",
        currency_name: "US Dollar",
        value: "USD (US Dollar)",
      },
      {
        country: "Qatar",
        currency_code: "QAR",
        country_code: "QA",
        currency_name: "Qatari Rial",
        value: "QAR (Qatari Rial)",
      },
      {
        country: "Reunion",
        currency_code: "EUR",
        country_code: "RE",
        currency_name: "Euro",
        value: "EUR (Euro)",
      },
      {
        country: "Romania",
        currency_code: "RON",
        country_code: "RO",
        currency_name: "Romanian Leu",
        value: "RON (Romanian Leu)",
      },
      {
        country: "Russia",
        currency_code: "RUB",
        country_code: "",
        currency_name: "Russian Ruble",
        value: "RUB (Russian Ruble)",
      },
      {
        country: "Rwanda",
        currency_code: "RWF",
        country_code: "",
        currency_name: "Rwandan Franc",
        value: "RWF (Rwandan Franc)",
      },
      // { country: 'Saint Helena', currency_code: 'SHP', country_code: 'SH', currency_name: '', value: 'SHP ()' },
      // { country: 'Saint Kitts and Nevis', currency_code: 'XCD', country_code: 'KN', currency_name: '', value: 'XCD ()' },
      // { country: 'Saint Lucia', currency_code: 'XCD', country_code: 'LC', currency_name: '', value: 'XCD ()' },
      {
        country: "Saint Pierre and Miquelon",
        currency_code: "EUR",
        country_code: "PM",
        currency_name: "Euro",
        value: "EUR (Euro)",
      },
      // { country: 'Saint Vincent and the Grenadines', currency_code: 'XCD', country_code: 'VC', currency_name: '', value: 'XCD ()' },
      // { country: 'Samoa', currency_code: 'WST', country_code: 'WS', currency_name: '', value: 'WST ()' },
      {
        country: "San Marino",
        currency_code: "EUR",
        country_code: "SM",
        currency_name: "Euro",
        value: "EUR (Euro)",
      },
      // { country: 'Sao Tome and Principe', currency_code: 'STD', country_code: 'ST', currency_name: '', value: 'STD ()' },
      {
        country: "Saudi Arabia",
        currency_code: "SAR",
        country_code: "SA",
        currency_name: "Saudi Riyal",
        value: "SAR (Saudi Riyal)",
      },
      {
        country: "Scotland",
        currency_code: "GBP",
        country_code: "",
        currency_name: "British Pound Sterling",
        value: "GBP (British Pound Sterling)",
      },
      {
        country: "Senegal",
        currency_code: "XOF",
        country_code: "SN",
        currency_name: "CFA Franc BCEAO",
        value: "XOF (CFA Franc BCEAO)",
      },
      {
        country: "Serbia",
        currency_code: "RSD",
        country_code: "",
        currency_name: "Serbian Dinar",
        value: "RSD (Serbian Dinar)",
      },
      // { country: 'Seychelles', currency_code: 'SCR', country_code: 'SC', currency_name: '', value: 'SCR ()' },
      // { country: 'Sierra Leone', currency_code: 'SLL', country_code: 'SL', currency_name: '', value: 'SLL ()' },
      {
        country: "Singapore",
        currency_code: "SGD",
        country_code: "SG",
        currency_name: "Singapore Dollar",
        value: "SGD (Singapore Dollar)",
      },
      {
        country: "Slovakia",
        currency_code: "EUR",
        country_code: "SK",
        currency_name: "Euro",
        value: "EUR (Euro)",
      },
      {
        country: "Slovenia",
        currency_code: "EUR",
        country_code: "SI",
        currency_name: "Euro",
        value: "EUR (Euro)",
      },
      // { country: 'Solomon Islands', currency_code: 'SBD', country_code: 'SB', currency_name: '', value: 'SBD ()' },
      {
        country: "Somalia",
        currency_code: "SOS",
        country_code: "SO",
        currency_name: "Somali Shilling",
        value: "SOS (Somali Shilling)",
      },
      {
        country: "South Africa",
        currency_code: "ZAR",
        country_code: "ZA",
        currency_name: "South African Rand",
        value: "ZAR (South African Rand)",
      },
      {
        country: "South Georgia and the South Sandwich Islands",
        currency_code: "GBP",
        country_code: "GS",
        currency_name: "British Pound Sterling",
        value: "GBP (British Pound Sterling)",
      },
      {
        country: "South Korea",
        currency_code: "KRW",
        country_code: "",
        currency_name: "South Korean Won",
        value: "KRW (South Korean Won)",
      },
      // { country: 'South Sudan', currency_code: 'SSP', country_code: '', currency_name: '', value: 'SSP ()' },
      {
        country: "Spain",
        currency_code: "EUR",
        country_code: "ES",
        currency_name: "Euro",
        value: "EUR (Euro)",
      },
      {
        country: "Sri Lanka",
        currency_code: "LKR",
        country_code: "LK",
        currency_name: "Sri Lankan Rupee",
        value: "LKR (Sri Lankan Rupee)",
      },
      {
        country: "Sudan",
        currency_code: "SDG",
        country_code: "SD",
        currency_name: "Sudanese Pound",
        value: "SDG (Sudanese Pound)",
      },
      // { country: 'Suriname', currency_code: 'SRD', country_code: 'SR', currency_name: '', value: 'SRD ()' },
      {
        country: "Svalbard and Jan Mayen",
        currency_code: "NOK",
        country_code: "SJ",
        currency_name: "Norwegian Krone",
        value: "NOK (Norwegian Krone)",
      },
      {
        country: "Sweden",
        currency_code: "SEK",
        country_code: "SE",
        currency_name: "Swedish Krona",
        value: "SEK (Swedish Krona)",
      },
      {
        country: "Switzerland",
        currency_code: "CHF",
        country_code: "CH",
        currency_name: "Swiss Franc",
        value: "CHF (Swiss Franc)",
      },
      {
        country: "Syria",
        currency_code: "SYP",
        country_code: "",
        currency_name: "Syrian Pound",
        value: "SYP (Syrian Pound)",
      },
      // { country: 'Tajikistan', currency_code: 'TJS', country_code: 'TJ', currency_name: '', value: 'TJS ()' },
      {
        country: "Tanzania",
        currency_code: "TZS",
        country_code: "",
        currency_name: "Tanzanian Shilling",
        value: "TZS (Tanzanian Shilling)",
      },
      {
        country: "Thailand",
        currency_code: "THB",
        country_code: "TH",
        currency_name: "Thai Baht",
        value: "THB (Thai Baht)",
      },
      {
        country: "The Democratic Republic of Congo",
        currency_code: "CDF",
        country_code: "",
        currency_name: "Congolese Franc",
        value: "CDF (Congolese Franc)",
      },
      {
        country: "Togo",
        currency_code: "XOF",
        country_code: "TG",
        currency_name: "CFA Franc BCEAO",
        value: "XOF (CFA Franc BCEAO)",
      },
      {
        country: "Tokelau",
        currency_code: "NZD",
        country_code: "TK",
        currency_name: "New Zealand Dollar",
        value: "NZD (New Zealand Dollar)",
      },
      {
        country: "Tonga",
        currency_code: "TOP",
        country_code: "TO",
        currency_name: "Tongan Paʻanga",
        value: "TOP (Tongan Paʻanga)",
      },
      {
        country: "Trinidad and Tobago",
        currency_code: "TTD",
        country_code: "TT",
        currency_name: "Trinidad and Tobago Dollar",
        value: "TTD (Trinidad and Tobago Dollar)",
      },
      {
        country: "Tunisia",
        currency_code: "TND",
        country_code: "TN",
        currency_name: "Tunisian Dinar",
        value: "TND (Tunisian Dinar)",
      },
      {
        country: "Turkey",
        currency_code: "TRY",
        country_code: "TR",
        currency_name: "Turkish Lira",
        value: "TRY (Turkish Lira)",
      },
      // { country: 'Turkmenistan', currency_code: 'TMT', country_code: 'TM', currency_name: '', value: 'TMT ()' },
      {
        country: "Turks and Caicos Islands",
        currency_code: "USD",
        country_code: "TC",
        currency_name: "US Dollar",
        value: "USD (US Dollar)",
      },
      {
        country: "Tuvalu",
        currency_code: "AUD",
        country_code: "TV",
        currency_name: "Australian Dollar",
        value: "AUD (Australian Dollar)",
      },
      {
        country: "Uganda",
        currency_code: "UGX",
        country_code: "UG",
        currency_name: "Ugandan Shilling",
        value: "UGX (Ugandan Shilling)",
      },
      {
        country: "Ukraine",
        currency_code: "UAH",
        country_code: "UA",
        currency_name: "Ukrainian Hryvnia",
        value: "UAH (Ukrainian Hryvnia)",
      },
      {
        country: "United Arab Emirates",
        currency_code: "AED",
        country_code: "AE",
        currency_name: "United Arab Emirates Dirham",
        value: "AED (United Arab Emirates Dirham)",
      },
      {
        country: "United Kingdom",
        currency_code: "GBP",
        country_code: "GB",
        currency_name: "British Pound Sterling",
        value: "GBP (British Pound Sterling)",
      },
      {
        country: "United States",
        currency_code: "USD",
        country_code: "US",
        currency_name: "US Dollar",
        value: "USD (US Dollar)",
      },
      {
        country: "United States Minor Outlying Islands",
        currency_code: "USD",
        country_code: "UM",
        currency_name: "US Dollar",
        value: "USD (US Dollar)",
      },
      {
        country: "Uruguay",
        currency_code: "UYU",
        country_code: "UY",
        currency_name: "Uruguayan Peso",
        value: "UYU (Uruguayan Peso)",
      },
      {
        country: "Uzbekistan",
        currency_code: "UZS",
        country_code: "UZ",
        currency_name: "Uzbekistan Som",
        value: "UZS (Uzbekistan Som)",
      },
      // { country: 'Vanuatu', currency_code: 'VUV', country_code: 'VU', currency_name: '', value: 'VUV ()' },
      {
        country: "Venezuela",
        currency_code: "VEF",
        country_code: "VE",
        currency_name: "Venezuelan Bolívar",
        value: "VEF (Venezuelan Bolívar)",
      },
      {
        country: "Vietnam",
        currency_code: "VND",
        country_code: "",
        currency_name: "Vietnamese Dong",
        value: "VND (Vietnamese Dong)",
      },
      {
        country: "Virgin Islands, British",
        currency_code: "USD",
        country_code: "VG",
        currency_name: "US Dollar",
        value: "USD (US Dollar)",
      },
      {
        country: "Virgin Islands, U.S.",
        currency_code: "USD",
        country_code: "VI",
        currency_name: "US Dollar",
        value: "USD (US Dollar)",
      },
      {
        country: "Wales",
        currency_code: "GBP",
        country_code: "",
        currency_name: "British Pound Sterling",
        value: "GBP (British Pound Sterling)",
      },
      // { country: 'Wallis and Futuna', currency_code: 'XPF', country_code: 'WF', currency_name: '', value: 'XPF ()' },
      {
        country: "Western Sahara",
        currency_code: "MAD",
        country_code: "EH",
        currency_name: "Moroccan Dirham",
        value: "MAD (Moroccan Dirham)",
      },
      {
        country: "Yemen",
        currency_code: "YER",
        country_code: "YE",
        currency_name: "Yemeni Rial",
        value: "YER (Yemeni Rial)",
      },
      // { country: 'Zambia', currency_code: 'ZMW', country_code: 'ZM', currency_name: '', value: 'ZMW ()' },
      // { country: 'Zimbabwe', currency_code: 'ZWD', country_code: 'ZW', currency_name: '', value: 'ZWD ()' }
    ],
    colorListing: [
      // Listing of 50 colors
      { color: "#FF0000", text: "#000000" }, // Red
      { color: "#00FF00", text: "#000000" }, // Green
      { color: "#0000FF", text: "#FFFFFF" }, // Blue
      { color: "#FFFF00", text: "#000000" }, // Yellow
      { color: "#00FFFF", text: "#000000" }, // Cyan
      { color: "#FF00FF", text: "#FFFFFF" }, // Magenta
      { color: "#FFA500", text: "#000000" }, // Orange
      { color: "#800080", text: "#FFFFFF" }, // Purple
      { color: "#FFC0CB", text: "#000000" }, // Pink
      { color: "#A52A2A", text: "#FFFFFF" }, // Brown
      { color: "#808080", text: "#FFFFFF" }, // Gray
      { color: "#000000", text: "#FFFFFF" }, // Black
      { color: "#87CEEB", text: "#000000" }, // Sky Blue
      { color: "#FFD700", text: "#000000" }, // Gold
      { color: "#C0C0C0", text: "#000000" }, // Silver
      { color: "#008080", text: "#FFFFFF" }, // Teal
      { color: "#000080", text: "#FFFFFF" }, // Navy
      { color: "#808000", text: "#FFFFFF" }, // Olive
      { color: "#FF7F50", text: "#000000" }, // Coral
      { color: "#00FF00", text: "#000000" }, // Lime
      { color: "#E6E6FA", text: "#000000" }, // Lavender
      { color: "#4B0082", text: "#FFFFFF" }, // Indigo
      { color: "#FFDAB9", text: "#000000" }, // Peach
      { color: "#98FF98", text: "#000000" }, // Mint
      { color: "#FF6347", text: "#000000" }, // Tomato
      { color: "#B22222", text: "#FFFFFF" }, // Firebrick
      { color: "#7FFF00", text: "#000000" }, // Chartreuse
      { color: "#D2691E", text: "#FFFFFF" }, // Chocolate
      { color: "#DC143C", text: "#FFFFFF" }, // Crimson
      { color: "#4682B4", text: "#FFFFFF" }, // Steel Blue
      { color: "#B0E0E6", text: "#000000" }, // Powder Blue
      { color: "#ADFF2F", text: "#000000" }, // Green Yellow
      { color: "#F0E68C", text: "#000000" }, // Khaki
      { color: "#FF1493", text: "#FFFFFF" }, // Deep Pink
      { color: "#8B0000", text: "#FFFFFF" }, // Dark Red
      { color: "#32CD32", text: "#000000" }, // Lime Green
      { color: "#3CB371", text: "#FFFFFF" }, // Medium Sea Green
      { color: "#2F4F4F", text: "#FFFFFF" }, // Dark Slate Gray
      { color: "#C71585", text: "#FFFFFF" }, // Medium Violet Red
      { color: "#800000", text: "#FFFFFF" }, // Maroon
      { color: "#FF4500", text: "#FFFFFF" }, // Orange Red
      { color: "#708090", text: "#FFFFFF" }, // Slate Gray
      { color: "#F4A460", text: "#000000" }, // Sandy Brown
      { color: "#FAEBD7", text: "#000000" }, // Antique White
      { color: "#B8860B", text: "#FFFFFF" }, // Dark Goldenrod
      { color: "#8A2BE2", text: "#FFFFFF" }, // Blue Violet
      { color: "#9ACD32", text: "#000000" }, // Yellow Green
      { color: "#7CFC00", text: "#000000" }, // Lawn Green
      { color: "#20B2AA", text: "#FFFFFF" }, // Light Sea Green
      { color: "#D3D3D3", text: "#000000" }, // Light Gray
      { color: "#FFB6C1", text: "#000000" }, // Light Pink
      { color: "#B0C4DE", text: "#000000" }, // Light Steel Blue
      { color: "#32CD32", text: "#000000" }, // Lime Green
      { color: "#4169E1", text: "#FFFFFF" }, // Royal Blue
    ],

    mockWorkspaceStandard: [],
  },
  mutations: {
    showAlert() {}, // Nothing in this mutation since it is acting as a global event handler only
    showAlertQuestion() {}, // Nothing in this mutation since it is acting as a global even handler only
    questionAnswered() {}, // Nothing in this mutation since it is acting as a global even handler only
    showNoti() {}, // Nothing in this mutation since it is acting as a global even handler only
  },
  actions: {},
  modules: {},
});
