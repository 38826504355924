<template>
  <div :class="styles.fileUploadContainer">
    <div
      :class="[styles.fileUploadInput, isDragging && styles.isDrag__]"
      @click="handleClickFileInput"
      @dragover.prevent="handleDragOver"
      @dragleave="handleDragLeave"
      @drop.prevent="handleFileDrop"
    >
      <img
        :class="styles.fileUploadIcon"
        src="../assets/fileupload/upload.png"
        alt="Upload File"
      />
      <div v-if="isDragging">Drop it here</div>
      <slot v-if="!isDragging">
        {{ props.placeholder }}
      </slot>
    </div>
    <div :class="[styles.fileList, fileList.length && styles.marginTop__]">
      <div
        :class="styles.fileListItem"
        v-for="(file, idx) in fileList"
        :key="idx"
        @click="emit('clickFile', file)"
      >
        <span :class="styles.fileListName" :title="file?.name">
          {{ file?.name }}
        </span>
        <img
          :class="styles.fileListRemove"
          src="../assets/fileupload/cross-red.png"
          @click.stop="handleRemoveFile(idx)"
          alt="Delete File"
        />
      </div>
    </div>
    <input
      ref="fileInput"
      type="file"
      hidden
      @change="handleFileUploadChange"
      :multiple="multiple"
      :accept="accept"
    />
  </div>
</template>

<script setup lang="ts">
import {
  FileUploadEmits,
  FileUploadProps,
} from "./componentModel/FileUploadModel";
import { withDefaults, defineProps, ref, defineEmits, watch } from "vue";

//#region Data
const fileInput = ref<HTMLInputElement | null>(null);
const fileList = ref<File[]>([]);
const isDragging = ref(false);
//#endregion

const props = withDefaults(defineProps<FileUploadProps>(), {
  accept: "",
  multiple: true,
  placeholder: "Drag file here or browse",
});
const emit = defineEmits<FileUploadEmits>();

//#region Handler
const handleClickFileInput = () => {
  fileInput.value?.click();
};

const handleDragOver = () => {
  isDragging.value = true;
};

const handleDragLeave = () => {
  isDragging.value = false;
};

const handleFileDrop = (ev: DragEvent) => {
  isDragging.value = false;
  if (ev.dataTransfer && ev.dataTransfer.files) {
    const droppedFiles = Array.from(ev.dataTransfer.files);
    droppedFiles.forEach((file) => {
      fileList.value.push(file);
    });

    updateFileList(droppedFiles);
  }
};

const handleFileUploadChange = (ev: Event) => {
  let target = ev.target as HTMLInputElement;
  if (target.files) {
    const selectedFiles = Array.from(target.files);
    selectedFiles.forEach((file) => {
      fileList.value.push(file);
    });

    updateFileList(selectedFiles);
  }
};

const handleRemoveFile = (index: number) => {
  fileList.value.splice(index, 1);
  emit("update:fileList", fileList.value);
};

const updateFileList = (file: File[]) => {
  fileList.value = [...file];
  emit("update:fileList", fileList.value);
  emit("fileChange", fileList.value);
};
//#endregion

watch(() => props.fileList, (val) => {
  if (val) {
    fileList.value = val;
  }
})
</script>

<style module="styles" lang="scss">
.fileUploadContainer {
  width: 100%;
  max-width: 100%;
}
.fileUploadInput {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border: 1px dashed var(--black);
  border-radius: 10px;
  cursor: pointer;
  padding: 16px;
  transition: all 300ms;

  &.isDrag__ {
    background: var(--secondary-opacity);
  }
}
.fileUploadIcon {
  width: 60px;
}
.ul {
  text-decoration: underline;
  color: var(--font-color);
  font-weight: 600;
}
.fileList {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 200px));
  gap: 8px;
  cursor: pointer;

  &.marginTop__ {
    margin-top: 8px;
  }
}
.fileListItem {
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
  gap: 8px;
  background: #f5f5f8;
  border-radius: 10px;
  padding: 8px 12px;
}
.fileListName {
  font-size: var(--font-small);
  display: -webkit-box;
  line-clamp: 1;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}
.fileListRemove {
  height: 14px;
  width: 14px;
  cursor: pointer;
}
</style>
