import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "loading-main" }
const _hoisted_2 = { class: "loading-text" }


import { LoaderProps } from './componentModel/LoaderModel';


export default /*@__PURE__*/_defineComponent({
  __name: 'Loader',
  props: {
    loadingText: { default: 'Loading' }
  },
  setup(__props: any) {

const props = __props;

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _cache[0] || (_cache[0] = _createElementVNode("div", { class: "loading-circle" }, null, -1)),
    _createElementVNode("div", _hoisted_2, _toDisplayString(props.loadingText), 1)
  ]))
}
}

})