<template>
  <div :class="styles.card">
    <div v-if="props.title" :class="styles.cardTitle">{{ props.title }}</div>
    <div :class="props.class">
      <slot></slot>
    </div>
  </div>
</template>

<script setup lang="ts">
import { defineProps } from "vue";
import { CardProps } from "./componentModel/CardModel";

//#region Data
//#endregion
const props = defineProps<CardProps>();
</script>

<style module="styles" lang="scss">
.card {
  display: flex;
  flex-direction: column;
  border-radius: 15px;
  background: var(--white);
  padding: 16px 20px;
}
.cardTitle {
  font-size: var(--font-large);
  margin-bottom: 8px;
}
</style>
